import React from "react";

// Creating the context object and passing the default values.
const AuthContext = React.createContext({
  status: false,
  currentChannel: null,
  setCurrentChannel: () => {},
  isReadPrivate: true,
  isReadPublic: true,
  setIsReadPrivate: () => {},
  setIsReadPublic: () => {},
  userProfile: {},
  supplieStatusCount: {OPEN:0, Pending: 0},
  pendingSupplyStatus: {},
  setPendingSupplyStatus: {},
  highPrioritySupply: {},
  issueCount: {},
  setIssueCount: {},
  resetPendingSupplyStatus: () => {},
  resetHighPrioritySupply: () => {},
  resetIssueCount: () => {},
  login: () => {},
  setEditAppointmentData: () => {},
  editAppointmentData: {},
  setUpdateAppointmentData: () => {},
  setAppointmentsData: () => {},
  appointmentsData: {},
  updateAppointmentData: {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  selectedAppointment: {},
  setSelectedAppointment: () => {},
  isExpanded: false,
  setIsExpanded: () => {},
  logoutuser: () => {},
  newClientId : {},
  setNewClientId : () => {},
  newEmpId : {},
  setNewEmpId : () => {},
  isLoading: false,
  tableState: {
    sort: false,
    rowsPerPage: 10,
  },
  setTableState: () => {},
});

export default AuthContext;
