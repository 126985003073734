/* eslint-disable react-hooks/exhaustive-deps */
import {
  faExclamation,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, IconButton} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import PageTitle from "../../components/pageTitle/PageTitle";
import SingleSelect from "../../components/SelectField/SingleSelect";
import TableToolbar from "../../components/Table/TableToolbar";
import ThemeTable from "../../components/Table/ThemeTable";
import CommonContext from "../../hooks/commonContext";
import AuthContext from "../../hooks/authContext.js";
import {
  datetimest,
  getFormatedDate,
  getFormatedTime,
  getTimeZoneDate,
  groupByKey,
} from "../../services/helpers";
import EditIcon from "@material-ui/icons/Edit";
import { getIssuesList, updateBoldStatus } from "../../services/apiservices/issues.js";
import { Columns, ColumnsRes } from "./IssuesManageTableColumns";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { useMediaQuery } from "@mui/material";
import IssueCommonCard from "../../components/ResponsiveCardComponent/IssuesComponent/IssuesListPageResponsive.jsx";
import { getAllEmployee } from "../../services/apiservices/employee.js";

function IssueManagement(props) {
  const history = useNavigate();
  const commonContext = useContext(CommonContext);
  const [allIssues, setAllIssues] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [allClient, setAllClients] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [allPriority, setAllPriority] = useState([]);
  const { userProfile } = useContext(AuthContext);
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = useState(null);
  const [filter, setFilterValues] = React.useState({
    priority: "any",
    client: "any",
    status: "all",
  });

  const checkFilteredList = () => {
    if (allIssues) {

      let filteredSuppliesList = allIssues?.filter((row) => {
        let searchFilter = true,
          filterstatus = true,
          filterclient = true,
          filterpriority = true;
        
        if (filter?.status !== "" && filter?.status !== "all") {
          filterstatus =
            String(row.status).toLowerCase() ===
            String(filter.status).toLowerCase();
        }
        if (filter?.client !== "" && filter?.client !== "any") {
          filterclient =
            String(row.clientName).toLowerCase() ===
            String(filter.client).toLowerCase();
        }
        if (filter?.priority !== "" && filter?.priority !== "any") {
          filterpriority =
            String(row.priority).toLowerCase() ===
            String(filter.priority).toLowerCase();
        }

        return searchFilter && filterstatus && filterclient && filterpriority;
      });
      setFilteredList(filteredSuppliesList);
    }
  };

  useEffect(() => {
    checkFilteredList();
  }, [allIssues, filter]);

  const manageDataList = (newRows) => {
    let allStatus = groupByKey(newRows, "status");
    let allStatusOptions = Object.keys(allStatus)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllStatus = allStatusOptions ? allStatusOptions : [];
    setAllStatus([...finalSetAllStatus]);


    // set data for filter fields
    let allclients = groupByKey(newRows, "clientName");
    let allclientOptions = Object.keys(allclients)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllClient = allclientOptions ? allclientOptions : [];
    setAllClients([...finalSetAllClient]);


    let allPriority = groupByKey(newRows, "priority");
    let allPriorityOptions =
    allPriority &&
      Object.keys(allPriority).map((aacitem) => {
        return { label: aacitem, value: aacitem };
      });
    let finalSetAllPriority = allPriorityOptions ? allPriorityOptions : [];
    setAllPriority([...finalSetAllPriority]);

  };

  const fetchIssuesList = async () => {
    setIsLoading(true);
    try {
      const [employeeRes, issuesRes] = await Promise.all([
        new Promise((resolve) => getAllEmployee(resolve)),
        new Promise((resolve) => getIssuesList(resolve))
      ]);
  
      const Inactive = employeeRes?.data.filter(
        (item) => item.status === false || item.deleted
      );

      let filteredRecords = issuesRes.filter(item =>  !item.storedData || item.storedData.boldClass !== false || item.status === "Pending");
  
      if (filteredRecords.length === 0) {
        auth.resetIssueCount();
      } else {
        auth.setIssueCount(filteredRecords);
      }
  
      let newRows = issuesRes
        .map((ritem) => {
        const data = employeeRes?.data.find((item) => item.userId === ritem?.employee?.id);
         return{
          ...ritem,
          isuueId: `IS - ${String(ritem?.issueNumber).padStart(4, '0')}`,
          employeeName: ritem?.employee?.name,
          clientName: ritem?.client?.name,
          dateOfRequest: ritem?.dateOfRequest,
          inactive: Inactive,
          timeZone: data?.timeZone
        }});
        newRows = newRows.filter((ritem) =>
          !["Closed", "Resolved"].includes(ritem.status)
        );
  
      let sortedData = sortingFunction(newRows);
  
      await manageDataList(sortedData);
      await setAllIssues(sortedData);
    } catch (error) {
      console.log("Error fetching issues:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchIssuesList();
  }, []);

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.isuueId < b.isuueId) return -1;
      if (a.isuueId > b.isuueId) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };


  const setFilter = (name, value) => {
    setFilterValues({ ...filter, [name]: value });
  };

  const tableLeftComponent = () => {
    return <div className="d-flex flex-center"></div>;
  };
  const tableRightComponent = () => {
    return (
      <div className="d-flex flex-center w320 editEmp issueAlign pr-2">
          <div className="d-flex flex-center ml-12 w-100 mv-7">
            <SingleSelect
              label="Status"
              value={filter?.status ? filter?.status : "all"}
              className={`field w90`}
              name={"status"}
              onChange={(e) => {
                setFilter("status", e?.target?.value);
              }}
              options={
                allStatus
                  ? [{ label: "All", value: "all" }, ...allStatus]
                  : [{ label: "All", value: "all" }]

              }
            />
          </div>
          <div className="d-flex flex-center ml-12 w-100 mv-7" >
            <SingleSelect
              label="Client"
              value={filter?.client ? filter?.client : "any"}
              className={`field w90`}
              name={"client"}
              onChange={(e) => {
                setFilter("client", e?.target?.value);
              }}
              options={
                allClient
                  ? [{ label: "Any", value: "any" }, ...allClient]
                  : [{ label: "Any", value: "any" }]
              }
            />
          </div>
          <div className="d-flex flex-center ml-12 w-100 mv-7">
            <SingleSelect
              label="Priority"
              value={filter?.priority ? filter?.priority : "any"}
              className={`field w90`}
              name={"priority"}
              onChange={(e) => {
                setFilter("priority", e?.target?.value);
              }}
              options={
                allPriority
                  ? [{ label: "Any", value: "any" }, ...allPriority]
                  : [{ label: "Any", value: "any" }]
              }
            />
          </div>
      </div>
    );
  };

  const onHandelClickFunction = async (id) => {
    const createdAt = datetimest();
    const formData = {
      boldClass: false,
      createdAt: createdAt,
    };
  
    const storedData = { id: userProfile.id, ...formData };
  
    await updateBoldStatus(id, storedData);
    await redirectToDetails(id);
  };

  const redirectToDetails = (id) => {
    if (typeof id !== "undefined" && id !== "")
      history("/issues/manage/" + id);
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    let boldclasss = rcellItem?.storedData?.boldClass !== false ? "fw-bold" : "";
    switch (hcellItem.id) {
      case "dateOfRequest":
        return (
          <p className={boldclasss}>
            {getFormatedDate(rcellItem?.dateOfRequest)}{" "}
          </p>
        );
      case "lastupdated":
        return (
          <p className={boldclasss}>{getTimeZoneDate(rcellItem?.updatedAt, rcellItem?.timeZone)} </p>
        );
      case "lastupdatedtime":
        return (
          <p className={boldclasss}>{getFormatedTime(rcellItem?.updatedAt, rcellItem?.timeZone)} </p>
        );
      
      case "employeeName":
        const isInactive = rcellItem?.inactive?.some((inactiveEmp) => inactiveEmp?.id === rcellItem?.employee.id);
        return (
          <p className={boldclasss} style={{color : isInactive ? "#9fa6b3" : "" }}>{rcellItem?.employeeName}</p>
        );

      case "priority":
        const hasMainAttachments = rcellItem?.attachments && rcellItem?.attachments.length > 0;

        const hasResponseAttachments = Object.values(rcellItem?.responses || {}).some(
          response => response?.attachments && response?.attachments.length > 0
        );
        return (

          <p className={boldclasss}>

            {rcellItem[hcellItem?.id]}
            {rcellItem[hcellItem?.id] === "High" && (


              <FontAwesomeIcon title="High Priority"
                className="color-danger mh-5"
                icon={faExclamation}
              />

            )}

            {rcellItem[hcellItem?.id] !== "High" && (
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation" class="svg-inline--fa fa-exclamation fa-w-6 color-danger mh-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" ></path></svg>
            )}
            {rcellItem.escalated_to_admin &&
              Boolean(rcellItem.escalated_to_admin) === true && (
                <FontAwesomeIcon title="Escalate to admin" className="mh-5 color-black" icon={faUsers} />
              )}
             {(hasMainAttachments || hasResponseAttachments) && (
              <FontAwesomeIcon title="Attachment Available" className="mh-5 color-black" size="lg" icon={faImage}/>
            )}
          </p>

        );
      case "action":
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => onHandelClickFunction(rcellItem?.id)}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      default:
        return <p className={boldclasss}>{rcellItem[hcellItem?.id]} </p>;
    }
  };
  
  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };


  return (
    <Container>
      <PageTitle
        pageTitle="Issue Management"
        showBreadcrumb={[{ label: "Home", to: "/" }]}
        showLastSeen={true}
        />

      <div className={`sectionBox2`}>
        <TableToolbar
          title=""
          leftComponent={tableLeftComponent()}
          rightComponent={tableRightComponent()}
          />
         {(!isMobile && !isLandscape) && 
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            hidePagination={false}
            isLoading={isLoading}
            renderRowCell={renderRowCell}
            sorting={true}
          />
        } 
        {(isMobile || isLandscape) && (
          <>
            {filteredList.map((record, index) => {
              const isOpen = expanded === record?.id;
              return (
                <IssueCommonCard
                  index={index}
                  record={record}
                  isOpen={isOpen}
                  handleExpandClick={handleExpandClick}
                  redirectToDetails={onHandelClickFunction}
                  columns={ColumnsRes}
                  issue={true}
                />
              );
            })}
          </>
        )}
      </div>
    </Container>
  );
}

export default IssueManagement;
