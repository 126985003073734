import React, { useState } from "react";
import { Button, Container, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import MultiSelect from "../../../components/SelectField/MultiSelect";
import { generateTimes } from "../../../services/helpers";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../Accounts.module.scss";
import PageTitle from "../../../components/pageTitle/PageTitle";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    selectinput: {
        padding: "10px 10px"
    },
    selectlabel: {
        transform: "translate(14px, 15px) scale(1)"
    },
    selectoption: {
        margin: 0
    }
}));

const AllowWorkOutsideWorkWeek = ({ isMobile, isLandscape, workType, setWorkType }) => {

    const handleToggle = (type) => {
        setWorkType(type);
    };

    return (
        <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
            <Button
                variant={workType === 'Enabled' ? 'contained' : 'outlined'}
                onClick={() => handleToggle('Enabled')}
                style={{
                    backgroundColor:
                        workType === 'Enabled' ? '#27ae60' : '#E0E0E0',
                    color: workType === 'Enabled' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '5px 0 0 5px',
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    border: 0,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                Enabled
            </Button>
            <Button
                variant={
                    workType === 'Disabled' ? 'contained' : 'outlined'
                }
                onClick={() => handleToggle('Disabled')}
                style={{
                    backgroundColor:
                        workType === 'Disabled' ? '#27ae60' : '#E0E0E0',
                    color: workType === 'Disabled' ? '#FFFFFF' : '#3d3d3d',
                    borderRadius: '0 5px 5px 0',
                    border: 0,
                    height: `${isMobile || isLandscape ? "40px" : "30px"}`,
                    boxShadow: 'none',
                    width: `${isMobile || isLandscape ? "50%" : "145px"}`
                }}
            >
                Disabled
            </Button>
        </div>
    );
};

const AppointmentPageSetting = () => {
    const [schedulingSystem, setSchedulingSystem] = useState("proteams");
    const schedulingSytemsOptions = [
        { label: "Proteams.io", value: "proteams" },
        { label: "Acuity", value: "acuity" }
    ];
    const [workWeek, setWorkWeek] = useState([]);
    const [workType, setWorkType] = useState('Enabled');
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const workWeeks = [
        { label: "Monday", value: "monday" },
        { label: "Tuesday", value: "tuesday" },
        { label: "Wednsday", value: "wednesday" },
        { label: "ThursDay", value: "thursday" },
        { label: "Friday", value: "friday" },
        { label: "Saturday", value: "saturday" },
        { label: "Sunday", value: "sunday" }
    ];
    const filteredWorkWeeks = workType === "Enabled"  ? workWeeks  : workWeeks.slice(0, 5);
    const timeOptions = generateTimes();
    const [standardWorkTime, setStandardWorkTime] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [startTimeInterval, setStartTimeInterval] = useState();
    const [endTimeInterval, setEndTimeInterval] = useState();
    const [earlyCheckIn, setEarlyCheckIn] = useState();
    const [timeFormat, setTimeFormat] = useState();
    const classes = useStyles();
    const standardWorkTimes = [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" }
    ];
    const timeIntervalOptions = [
        { label: "5 Min", value: "5 Min" },
        { label: "10 Min", value: "10 Min" },
        { label: "15 Min", value: "15 Min" },
        { label: "20 Min", value: "20 Min" },
        { label: "30 Min", value: "30 Min" },
    ];
    const earlyCheckInOptions = [
        { label: "Not Allowed", value: "notAllowed" },
        { label: "5 Min", value: "5 Min" },
        { label: "10 Min", value: "10 Min" },
        { label: "15 Min", value: "15 Min" },
        { label: "30 Min", value: "30 Min" },
    ];
    const TimeFormatsOptions = [
        { label: "12 Hrs", value: "12hrs" },
        { label: "24 Hrs", value: "24hrs" }
    ];
    const handelSchedulingSystem = (e) => {
        const system = e.target.value;
        setSchedulingSystem(system);
    };
    const handelChangeWorkWeek = (e) => {
        setWorkWeek(e);
    };

    const handelSaveData = () => {
        console.log("Data save")
    }

    return (
        <Container>
            <PageTitle
                pageTitle={`Appointment Settings`}
                showBreadcrumb={[
                    { label: "Home", to: "/" },
                    { label: "Settings", to: "/settings" },
                ]}
                BreadCrumbCurrentPageTitle={"Appointment"}
                showLastSeen={true}
            />
            <div className="sectionBox">
                <div className={`${isMobile ? "w-100" : "w-100 d-flex"}`}>
                    <div className={styles.widths}>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>Scheduling System</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <SingleSelect
                                    value={schedulingSystem}
                                    name={"schedulingSystem"}
                                    label={"Scheduling System"}
                                    onChange={(e) => handelSchedulingSystem(e)}
                                    options={schedulingSytemsOptions}
                                />
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>Allow Work Outside Week</div>
                            <AllowWorkOutsideWorkWeek
                                isMobile={isMobile}
                                isLandscape={isLandscape}
                                workType={workType}
                                setWorkType={setWorkType}
                                styles={styles}
                            />
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>Work Week</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <MultiSelect
                                    value={workWeek}
                                    name={"workweek"}
                                    label={"Work Week"}
                                    onChange={(e) => handelChangeWorkWeek(e)}
                                    options={filteredWorkWeeks}
                                />
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}>Standard Start Time</div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel className={classes.selectlabel}>Standard Start Time</InputLabel>
                                    <Select
                                        value={startTime}
                                        onChange={(event) => {
                                            setStartTime(event.target.value);
                                        }}
                                        label="Standard Start Time"
                                        inputProps={{ className: classes.selectinput }}
                                    >
                                        {timeOptions.map((time, index) => (
                                            <MenuItem key={index} value={time}>
                                                {time}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}> Standard End Time </div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel className={classes.selectlabel}>Standard End Time</InputLabel>
                                    <Select
                                        value={endTime}
                                        onChange={(event) => {
                                            setEndTime(event.target.value);
                                        }}
                                        label="Standard End Time"
                                        inputProps={{ className: classes.selectinput }}
                                    >
                                        {timeOptions.map((time, index) => (
                                            <MenuItem key={index} value={time}>
                                                {time}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className={`${isMobile ? `${styles.widths}` : `${styles.widths} ml-20`}`}>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}> OutSide Standard Work Time </div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <SingleSelect
                                    value={standardWorkTime}
                                    name={"standardworktime"}
                                    label={"OutSide Standard Work Time"}
                                    onChange={(e) => setStandardWorkTime(e.target.value)}
                                    options={standardWorkTimes}
                                />
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}> Start Time Interval </div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <SingleSelect
                                    value={startTimeInterval}
                                    name={"starttimeinterval"}
                                    label={"Start Time Interval"}
                                    onChange={(e) => setStartTimeInterval(e.target.value)}
                                    options={timeIntervalOptions}
                                />
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}> End Time Interval </div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <SingleSelect
                                    value={endTimeInterval}
                                    name={"endtimeinterval"}
                                    label={"End Time Interval"}
                                    onChange={(e) => setEndTimeInterval(e.target.value)}
                                    options={timeIntervalOptions}
                                />
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}> Early Check In </div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <SingleSelect
                                    value={earlyCheckIn}
                                    name={"earlyCheckIn"}
                                    label={"Early Check In"}
                                    onChange={(e) => setEarlyCheckIn(e.target.value)}
                                    options={earlyCheckInOptions}
                                />
                            </div>
                        </div>
                        <div className={`${isMobile ? "d-flex flex-direction-column mv-30 w-100" : "d-flex flex-center mv-30 w-100"}`}>
                            <div className={styles.label}> Time Formats </div>
                            <div className={`${isMobile ? `mt-10 ${styles.selectWidth}` : `ml-20 ${styles.selectWidth}`}`}>
                                <SingleSelect
                                    value={timeFormat}
                                    name={"timeFormat"}
                                    label={"Time Formats"}
                                    onChange={(e) => setTimeFormat(e.target.value)}
                                    options={TimeFormatsOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mv-15">
                    <Button variant="contained" color="primary" style={{ boxShadow: 'none' }} onClick={handelSaveData}>Save</Button>
                    <Button variant="contained" color="default" style={{ marginLeft: 10, boxShadow: 'none' }}>Cancel</Button>
                    <Button variant="contained" color="secondary" style={{ marginLeft: 10, boxShadow: 'none' }}>Reset</Button>
                </div>
            </div>
        </Container>
    );
}

export default AppointmentPageSetting;