/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import CommonContext from "../../hooks/commonContext";
import { FormatedDates } from "../../services/helpers";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Columns } from "./NotificationTableColumns";
import DialogComponent from "../../components/DialogComponent/DialogComponent";
import NotificationDetails from "./NotificationDetails";
import { getNotificationList } from "../../services/apiservices/notifications";

function Notification(props) {
  const commonContext = useContext(CommonContext);
  const [allNotifications, setAllNotifications] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchNotificationList = async () => {
    setIsLoading(true);
    await getNotificationList(
      async (res) => {
        if (res) {
          
          let datares = res.map((ritem) => {
            let groupobj =  ritem.Group;
            var valyt;
            for (const key in groupobj) {
              if(key==="title"){
                valyt = groupobj[key];
              }
               
            }

            return {...ritem, group:valyt};
          });
          datares.sort((a, b) => {
            return new Date(b.createdOn) - new Date(a.createdOn);
        });
          setAllNotifications(datares);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        setIsLoading(false);
      }
      
    );
    
  };
  useEffect(() => {
    fetchNotificationList();
  }, []);


  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "createdOn":
        return (
        <div className="notifWidth1"> 
        {FormatedDates(rcellItem?.createdOn)}
        </div>
        );
      case "group":
        return(
          <div className="notifWidth1">
            {rcellItem[hcellItem?.id]}
          </div>
        );
        case "Type":
          return (
            <div className="notifWidth2">
              {
                Array.isArray(rcellItem?.Type) ?
                  rcellItem?.Type.join(', ') :
                  rcellItem?.Type === "Push" ? "SMS" :
                  rcellItem?.Type === "PushNotification" ? "Push Notification" :
                  rcellItem?.Type === "Email" ? "Email" :
                  rcellItem?.Type === "Both" ? "All (Push Notification, Email, SMS)" :
                  rcellItem[hcellItem?.id]
              }
            </div>
          );        
      case "message":
        return <>{rcellItem?.message?.substring(0, 30) + "..."} </>;
      case "action":
        return (
          <>
            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"iconButton color-primary"}
              buttonProps={{ size: "small" }}
              buttonLabel={<VisibilityIcon />}
              ModalTitle={"Notification Details"}
              ModalContent={<NotificationDetails item={rcellItem} />}
            />
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };
  let filteredList = allNotifications;
  return (
    <Container>
      <PageTitle
        pageTitle="Notification"
        showBreadcrumb={[{ label: "Home", to: "/" }]}
        showLastSeen={true}
      />

      <div className={`sectionBox`}>

        <ThemeTable
          rows={filteredList}
          headCells={Columns}
          hidePagination={false}
          isLoading={isLoading}
          renderRowCell={renderRowCell}
        />
      </div>
    </Container>
  );
}

export default Notification;
