import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../hooks/authContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AlertOpen, DragReschduleConfirm, TimeZoneConflictsDialog } from '../../../components/DialogComponent/SupplyDataDialog';
import { calculateDurationInMinutes, filterAppointments, formatSelectedDateTime } from '../../../services/helpers';
import { getOneAppointment, updateAppointments } from '../../../services/apiservices/appoinments';
import CommonContext from '../../../hooks/commonContext';

const ITEM_TYPE = 'APPOINTMENT';

const DraggableAppointment = ({ index, filteredAppointments, appointment, client, boxHeight, setIsModalOpen, setSelectedAppointment }) => {
    const [, drag] = useDrag({
        type: ITEM_TYPE,
        item: { appointment },
    });

    return (
        <div
            ref={drag}
            className="appointmentView"
            style={{
                height: `${boxHeight}px`,
                width: `calc(100% / ${filteredAppointments})`,
                left: `${(index % filteredAppointments) * (100 / filteredAppointments)}%`,
            }}
            key={`appointment-${appointment.id}`}
            onClick={() => {
                setIsModalOpen(true);
                setSelectedAppointment(appointment);
            }}
        >
            <div className="appointmentView-title">{client?.clientName}</div>
            <div className="appointmentView-details2 mv-10">
                {appointment.apoointmentDetails?.forms[0]?.values[1]?.value} - {appointment.apoointmentDetails?.forms[0]?.values[2]?.value}
            </div>
            <div className="appointmentView-details mv-10">{appointment.apoointmentDetails.shiftName}</div>
            <div className="appointmentView-details2 mv-10">
                {appointment.apoointmentDetails.time} - {appointment.apoointmentDetails.endTime}
            </div>
        </div>
    );
};

const DroppableTimeSlot = ({ hour, day, timezone, onDrop, children }) => {
    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        drop: (item) => {
            const { appointment } = item;
            onDrop(appointment, hour, day, timezone);
        },
    });

    return (
        <div ref={drop} className="time-slot">
            {children}
        </div>
    );
};


const WeekView = ({ weeksAllDays, clientDetails, empNames, ManageAppointmentsData, clientName, employeeDetails }) => {
    const timeSlots = Array.from({ length: 13 }, (_, i) => i + 7);
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [widths, setWidths] = useState([]);
    const [appointmentsData, setAppointmentsData] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [timeZoneDialog, setTimeZoneDialog] = useState(false);
    const [loading, setLoading] = useState(true); 
    const [selectData, setSelectData] = useState();
    const commonContext = useContext(CommonContext);
    const timeZones = [
        { label: "EDT - Eastern", value: "America/New_York" },
        { label: "CDT - Central", value: "America/Chicago" },
        { label: "MDT - Mountain", value: "America/Denver" },
        { label: "PDT - Pacific", value: "America/Los_Angeles" }
    ];
    const auth = useContext(AuthContext);
    const appointments = auth.appointmentsData;
    const setIsModalOpen = auth.setIsModalOpen;
    const setSelectedAppointment = auth.setSelectedAppointment;

    const formatTime = (hour) => {
        const ampm = hour >= 12 ? 'pm' : 'am';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}${ampm}`;
    };

    const getAppointmentsForDay = (date) => {
        if (Array.isArray(appointments)) {
            const weekDateString = moment(date).format("YYYY-MM-DD");

            const filteredAppointments = appointments.filter((item) => {
                const appointmentDateString = moment(item.apoointmentDetails.date).format("YYYY-MM-DD");
                return appointmentDateString === weekDateString;
            });

            const normalizedDate = moment(date).startOf('day').format("YYYY-MM-DD");

            return filteredAppointments.filter((item) => {
                const appointmentDate = moment(item.apoointmentDetails.date).startOf('day').format("YYYY-MM-DD");
                return appointmentDate === normalizedDate;
            });
        }
        return [];
    };

    useEffect(() => {
        const calculateWidths = async () => {
            const widthsArray = [];

            weeksAllDays.forEach((day) => {
                const dailyAppointments = getAppointmentsForDay(day);

                timeZones.forEach((timezone) => {
                    const timezoneAppointments = dailyAppointments.filter(
                        (appointment) =>
                            (appointment.apoointmentDetails.calendarTimezone || "America/New_York") === timezone.value
                    );

                    let maxOverlaps = 0;

                    timezoneAppointments.forEach((appointment) => {
                        const start = moment(appointment.apoointmentDetails.time, "hh:mm a").tz(timezone.value);
                        const end = moment(appointment.apoointmentDetails.endTime, "hh:mm a").tz(timezone.value);

                        const overlappingAppointments = timezoneAppointments.filter((other) => {
                            const otherStart = moment(other.apoointmentDetails.time, "hh:mm a").tz(timezone.value);
                            const otherEnd = moment(other.apoointmentDetails.endTime, "hh:mm a").tz(timezone.value);
                            return (
                                start.isBefore(otherEnd) &&
                                end.isAfter(otherStart) &&
                                appointment !== other
                            );
                        });

                        maxOverlaps = Math.max(maxOverlaps, overlappingAppointments.length + 1);
                    });

                    const width = Math.max(400, maxOverlaps * 250);
                    widthsArray.push(width);
                });
            });

            setWidths(widthsArray);
        };

        setLoading(true); 
        calculateWidths().then(() => setLoading(false));
    }, [weeksAllDays]);

    const handleDrop = async (appointment, newHour, newDate, newTimezone) => {
        const inactiveEmp = employeeDetails?.data?.find((emp)=> emp?.userId === appointment?.userId);
        const currentDateTime = moment().utc();
        const appointmentDateTime = moment.utc(
            `${appointment?.apoointmentDetails?.date} ${appointment?.apoointmentDetails?.time}`,
            "MMMM DD, YYYY hh:mm A" 
        );
        const isDateValid = appointmentDateTime.isSameOrAfter(currentDateTime);
        if (appointment.source !== "proteams" || !isDateValid || inactiveEmp.status === false) {
            setAlertOpen(true);
            return;
        } else {
            const data = await getOneAppointment(appointment?.id);
            setSelectData(data?.appointment);
            const startHour = moment({ hour: newHour });
            const duration = appointment.apoointmentDetails.duration;
            const endHour = startHour.clone().add(duration, 'minutes');

            const formattedStartTime = startHour.format('hh:mm A');
            const formattedEndTime = endHour.format('hh:mm A');
            const date = moment(newDate).format('MM/DD/YYYY');
            const appointmentDateTime = formatSelectedDateTime(date, formattedStartTime, newTimezone);

            setDialogOpen(true);

            setAppointmentsData({
                ...data.appointment,
                time: formattedStartTime,
                endTime: formattedEndTime,
                type: "One Time",
                date: moment(newDate).format('LL'),
                appointmentDateTime: appointmentDateTime,
                calendarTimezone: newTimezone,
            })
        }
    };

    const handelConfirmCloseDialog = () => {
        setDialogOpen(false);
        setAppointmentsData();
        setSelectData();
    }
    const handelTimezoneConfirmcancel = () => {
        setTimeZoneDialog(false);
        setAppointmentsData();
        setSelectData();
    }
    const handelAlertClose = () => {
        setAlertOpen(false);
        setAppointmentsData();
        setSelectData();
    }

    const handelConfirmUpdateDilog = async () => {
        const oldTimezone = timeZones.find((item) => item.value === selectData.calendarTimezone)?.label;
        const newTimezone = timeZones.find((item) => item.value === appointmentsData.calendarTimezone)?.label;
        const timeZoneConflict = oldTimezone !== newTimezone;

        setDialogOpen(false);
        if (timeZoneConflict) {
            setTimeZoneDialog(true);
        } else {
            commonContext?.setLoader(true);
            const ids = appointmentsData?.id;
            const response = await updateAppointments(appointmentsData, null, ids);
            if (response.success) {
                commonContext?.setLoader(false);
                setAppointmentsData();
                setSelectData();
                await ManageAppointmentsData();
            } else {
                console.error("Failed to update appointment:", response.error);
                commonContext?.setLoader(false);
                setDialogOpen(false);
                setAppointmentsData();
                setSelectData();
            }
        }
    }

    const handelClickTimezoneConfirm = async () => {
        setTimeZoneDialog(false);
        commonContext?.setLoader(true);
        const ids = appointmentsData?.id;
        const response = await updateAppointments(appointmentsData, null, ids);
        if (response.success) {
            commonContext?.setLoader(false);
            setAppointmentsData();
            setSelectData();
            await ManageAppointmentsData();
        } else {
            console.error("Failed to update appointment:", response.error);
            commonContext?.setLoader(false);
            setTimeZoneDialog(false);
            setAppointmentsData();
            setSelectData();
        }
    }
    const TimeColumn = () => {
        return (
            timeSlots.map((hour, index) => (
                <div key={`time-slot-${index}`} className="time-column">
                    {formatTime(hour)}
                </div>
            ))
        );
    };

    const isGridReady = widths.length === weeksAllDays.length * timeZones.length;

    return (
         <>
            {loading || !isGridReady ? (
                // commonContext?.setLoader(true)
                null
            ) : (
                <DndProvider backend={HTML5Backend}>
                    {/* {commonContext?.setLoader(false)} */}
                <div className="calendar-container d-flex">
                    <div className='newTimeColumn'>
                        <div className="time-column" style={{ height: "38px", borderTop: "2px solid #ddd" }}></div>
                        <div className="time-column" style={{ height: "39px" }}><AccessTimeIcon fontSize="small" /></div>
                        <TimeColumn />
                    </div>
                    <div className="calendar-grid">
                        <div className="grid-row-day" style={{ display: "grid", gridTemplateColumns: `${widths?.map((width, index) => `${width}px`).join(" ")}` }}>
                            {weeksAllDays.map((day, index) => (
                                <div key={`day-${index}`} className="day-header" style={{ gridColumn: 'span 4' }}>
                                    {moment(day).format("dddd MMM D")}
                                </div>
                            ))}
                        </div>

                        <div className="grid-row-Time" style={{ display: "grid", gridTemplateColumns: `${widths?.map((width, index) => `${width}px`).join(" ")}` }}>
                            {weekDays.map(day =>
                                timeZones.map((timezone, timezoneIndex) => (
                                    <div key={`${day}-${timezoneIndex}`} className="timezone-header">
                                        {`${day.slice(0, 3)} ${timezone.label.split(" ")[0]}`}
                                    </div>
                                ))
                            )}
                        </div>

                        {timeSlots.map((hour) => (
                            <div
                                key={hour}
                                style={{ display: "grid", gridTemplateColumns: `${widths?.map((width) => `${width}px`).join(" ")}`, }}
                            >
                                {weeksAllDays.map((day, dayIndex) => {
                                    const dailyAppointments = getAppointmentsForDay(day);

                                    return timeZones.map((timezone, timezoneIndex) => {
                                        const timezoneAppointments = dailyAppointments.filter((appointment) =>
                                            (appointment.apoointmentDetails.calendarTimezone) === timezone.value
                                        );

                                        const filteredAppointments = filterAppointments(timezoneAppointments, clientName, empNames);                                   

                                        return (
                                            <DroppableTimeSlot
                                                key={`slot-${dayIndex}-${timezoneIndex}`}
                                                hour={hour}
                                                day={day}
                                                timezone={timezone.value}
                                                onDrop={handleDrop}
                                            >
                                                {filteredAppointments.map((appointment, index) => {
                                                    const clientId = appointment.apoointmentDetails.clientId ? appointment.apoointmentDetails.clientId : appointment.clientId;
                                                    const client = clientDetails.find((client) => client.id === clientId);
                                                    const appointmentTimeSlot = moment(appointment.apoointmentDetails.time, "hh:mm a").hour();
                                                    const appointmentStartTime = appointment.apoointmentDetails.time;
                                                    let appointmentEndTime = appointment.apoointmentDetails.endTime;
                                                    const cutoffTime = moment("8:00 pm", "h:mm a");
                                                    const endTimeMoment = moment(appointmentEndTime, "h:mm a");
                                                    if (endTimeMoment.isAfter(cutoffTime)) {
                                                        appointmentEndTime = cutoffTime.format("h:mm a");
                                                    }
                                                    const duration = calculateDurationInMinutes(appointmentStartTime, appointmentEndTime);

                                                    const boxHeight = (duration / 60) * 100;
                                                    if (appointmentTimeSlot === hour) {
                                                        return (
                                                            <DraggableAppointment
                                                                key={`appointment-${dayIndex}-${index}`}
                                                                index={index}
                                                                filteredAppointments={filteredAppointments.length}
                                                                appointment={appointment}
                                                                client={client}
                                                                boxHeight={boxHeight}
                                                                setIsModalOpen={setIsModalOpen}
                                                                setSelectedAppointment={setSelectedAppointment}

                                                            />
                                                        );
                                                    }
                                                })}
                                            </DroppableTimeSlot>
                                        );
                                    });
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            </DndProvider>
             )}
            {dialogOpen && (
                <DragReschduleConfirm
                    open={dialogOpen}
                    onSingleAppt={handelConfirmUpdateDilog}
                    auth={selectData}
                    onClose={handelConfirmCloseDialog}
                    client={clientDetails}
                    data={appointmentsData}
                    timeZones={timeZones}
                />
            )}
            {timeZoneDialog && (
                <TimeZoneConflictsDialog
                    open={timeZoneDialog}
                    onConfirm={handelClickTimezoneConfirm}
                    onClose={handelTimezoneConfirmcancel}
                />
            )}
            {alertOpen && (
                <AlertOpen
                    open={alertOpen}
                    onClose={handelAlertClose}
                />
            )}
        </>
    );
};


export default WeekView;