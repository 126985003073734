import { collection, doc, getDoc, getDocs, query, where, setDoc, orderBy, updateDoc, serverTimestamp, addDoc, arrayUnion, Timestamp, documentId } from 'firebase/firestore';
import { firebase } from '../firebase-config';
import { COLLECTIONS } from '../config';
import { getAuth } from 'firebase/auth';
import {
  deleteItem,
  updateItem,
  addItem,
} from './firebaseBootstrap';
import dayjs from "dayjs";
const auth = getAuth();

const collectionSupplies = collection(firebase, COLLECTIONS.SUPPLIES_CLEANINGS);
const collectionOrder = collection(firebase, COLLECTIONS.SUPPLIES_ORDER);

export async function getSuppliesListCount(formData, onSuccess, onError) {
  const colRef = collectionOrder;
  const q = query(colRef, where('status', 'in', [...formData.status]));

  try {
    const querySnapshot = await getDocs(q);
    const results = [];
    const statusCount = {};

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      statusCount[data?.status] = (statusCount[data?.status] || 0) + 1;
    });

    formData.status.forEach((status) => {
      if (!statusCount[status]) {
        statusCount[status] = 0;
      }
    });

    results.push(statusCount);
    onSuccess && onSuccess(results);
  } catch (error) {
    console.error('Error getting documents: ', error);
    onError && onError(error);
  }
}

export async function getSupplyOrderList(formData, onSuccess, onError) {
  const colRef = collectionOrder;

  try {
    const querySnapshot = await getDocs(colRef);
    const results = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      results.push({ id: doc.id, ...data, ...data.content });
    });

    onSuccess && onSuccess(results);
  } catch (error) {
    console.error('Error getting documents: ', error);
    onError && onError(error);
  }
}

export async function getSuppliesByDate(formData, onSuccess, onError) {
  const colRef = collectionOrder;
  try {
    const startTimestamp = dayjs(formData.startDate).valueOf();
    const endTimestamp = dayjs(formData.endDate).valueOf(); 

    const filteredQuery = query(
      colRef,
      where("dateOfRequest", ">=", startTimestamp),
      where("dateOfRequest", "<=", endTimestamp)
    );

    const querySnapshot = await getDocs(filteredQuery);
    let results = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      results.push({ id: doc.id, ...data });
    });
    
    onSuccess && onSuccess(results);
    return results;
  } catch (error) {
    console.log("Error getting documents: ", error);
    onError && onError(error);
  }
}

export async function getSupplyOrderListBasedOnId(id, onSuccess, onError) {
  const docRef = doc(collectionOrder, id);

  try {
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const result = { id: docSnapshot.id, ...data, ...data.content };
      onSuccess && onSuccess(result);
    } else {
      // Document does not exist
      onSuccess && onSuccess(null);
    }
  } catch (error) {
    console.error('Error getting document: ', error);
    onError && onError(error);
  }
}

export async function getSuppliesList(formData, onSuccess, onError) {
  const colRef = collectionSupplies;

  try {
    let results = [];

    if (formData.status && formData.status.length > 0) {
      const q = query(colRef, where('status', 'in', [...formData.status]));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        results.push({ id: doc.id, ...data });
      });
    } else {
      const querySnapshot = await getDocs(colRef);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        results.push({ id: doc.id, ...data, ...data.content });
      });
    }

    onSuccess && onSuccess(results);
  } catch (error) {
    console.error('Error getting documents: ', error);
    onError && onError(error);
  }
}

export async function getSupplies(id, onSuccess, onError) {
  const supplyItemColRef = collectionSupplies;
  const supplyDocRef = doc(supplyItemColRef, id);

  try {
    const docSnapshot = await getDoc(supplyDocRef);

    if (!docSnapshot.exists()) {
      onError && onError({ status: false, error: 'Document not found' });
      return;
    }

    const data = docSnapshot.data();
    const docContent = { id: docSnapshot.id, ...data };

    // Fetching the associated SupplyItems
    const supplyItemsSnapshot = await getDocs(collection(supplyItemColRef, id, 'SupplieItems'));
    const suppliesItems = [];

    supplyItemsSnapshot.forEach((document) => {
      const docData = document.data();
      suppliesItems.push({ id: document.id, clientName: docContent.client?.name, ...docData });
    });

    onSuccess({
      status: true,
      data: docContent,
      SupplieItems: suppliesItems,
    });
  } catch (error) {
    console.error('Error getting document: ', error);
    onError && onError({ status: false, error: error });
  }
}

export async function getSuppliesByClientItems(formData, onSuccess, onError) {
  if (formData?.Clients && formData.Clients.length > 0) {
    const supplyItemColRef = collectionSupplies;
    const q = query(supplyItemColRef, where('client.name', 'in', [...formData.Clients]), orderBy('dateOfRequest', 'desc'));

    try {
      const querySnapshot = await getDocs(q);
      const results = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      onSuccess && onSuccess(results);
    } catch (error) {
      console.error('Error getting documents: ', error);
      onError && onError(error);
    }
  }
}

export async function addSupplies(formData, onSuccess, onError) {
  addItem('users', formData, onSuccess, onError);
}

export async function updateSupplies(id, formData, onSuccess, onError) {
  updateItem(collectionSupplies, id, formData, onSuccess, onError);
}

export async function updateSuppliesItemsData(
  id,
  SupplieItemsId,
  formData,
  saveStatus,
  onSuccess,
  onError
) {
  console.log('on change status', id, SupplieItemsId, formData);

  const colRef = collection(firebase, COLLECTIONS.SUPPLIES_ORDER);
  const docRef = doc(colRef, id);

  try {
    if (saveStatus === 'Approved') {
      await updateDoc(docRef, { status: saveStatus, orderStatus: 'Pending' });

      const itemRef = doc(docRef, 'SupplieItems', SupplieItemsId);
      const docSnap = await getDoc(itemRef);

      if (!docSnap.exists()) {
        onError({ status: false, error: 'Document not found' });
        return;
      }

      const oldDocData = docSnap.data();
      await updateDoc(itemRef, {
        ...oldDocData,
        ...formData,
      });

      onSuccess && onSuccess({ status: true, data: { ...oldDocData, ...formData } });
      console.log('updated');
    } else {
      const itemRef = doc(docRef, 'SupplieItems', SupplieItemsId);
      const docSnap = await getDoc(itemRef);

      if (!docSnap.exists()) {
        onError({ status: false, error: 'Document not found' });
        return;
      }

      const oldDocData = docSnap.data();
      await updateDoc(itemRef, {
        ...oldDocData,
        ...formData,
      });

      onSuccess && onSuccess({ status: true, data: { ...oldDocData, ...formData } });
    }
  } catch (error) {
    onError && onError(error);
  }
}


export async function saveRejectionReson(id, SupplieItemsId, formData, onSuccess, onError) {
  try {
    const itemRef = doc(collection(firebase, COLLECTIONS.SUPPLIES_ORDER), id, 'SupplieItems', SupplieItemsId);
    await updateDoc(itemRef, { ...formData });
    onSuccess && onSuccess({ status: true });
  } catch (error) {
    onError && onError(error);
  }
}

export async function deleteSupplies(id, onSuccess, onError) {
  if (!id || id === '') {
    const docRef = doc(collection(firebase, COLLECTIONS.SUPPLIES_ORDER), id);
    await deleteItem(docRef);
    onSuccess && onSuccess({ status: true });
  } else {
    onError && onError({ status: false, message: 'Invalid input' });
  }
}

export async function updateSuppliesStatus(id, formData, onSuccess, onError) {
  const currentUserId = auth.currentUser.uid;

  if (!id || id.trim() === "") {
    onError && onError({ status: false, message: "Id is Missing" });
    return;
  }

  try {
    const colRef = collection(firebase, COLLECTIONS.SUPPLIES_ORDER);
    const updateData = {
      ...formData,
      updateBy: currentUserId,
      updatedAt: serverTimestamp(),
      ...(formData.status === "Rejected" && { archive: true }),
    };

    const docRef = doc(colRef, id);
    await updateDoc(docRef, updateData);
    onSuccess && onSuccess({ status: true, data: formData });

  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, message: error.message });
  }
}

export async function getCategories(onSuccess, onError) {
  const colRef = collection(firebase, COLLECTIONS.SUPPLIES_CATEGORY);

  try {
    const querySnapshot = await getDocs(colRef);
    const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    if (onSuccess) {
      onSuccess(results);
    }
  } catch (error) {
    console.log('Error getting documents: ', error);
    if (onError) {
      onError(error);
    }
  }
}

export async function addNotes(formData, onSuccess, onError) {
  const notesColRef = collection(firebase, COLLECTIONS.SUPPLIES_ORDER_NOTES);
  const suppliesDocRef = doc(firebase, COLLECTIONS.SUPPLIES_ORDER, formData.orderId);

  try {
    const docRef = await addDoc(notesColRef, {
      ...formData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    const docData = { ...formData, id: docRef.id };

    const suppliesDocSnapshot = await getDoc(suppliesDocRef);

    if (suppliesDocSnapshot.exists()) {
        await updateDoc(suppliesDocRef, {
          msg: true,
          updatedAt: serverTimestamp(),
        });
      
    } else {
      throw new Error(`No document found with ID: ${formData.orderId}`);
    }

    if (onSuccess && typeof onSuccess === "function") {
      onSuccess({ status: true, data: docData });
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError({ status: false, error: error.message || error });
    }
  }
}

export async function fetchNotes(id, onSuccess, onError) {
  if (!id) {
    if (onError) {
      onError(new Error('No ID provided'));
    }
    return;
  }

  const colRef = collection(firebase, COLLECTIONS.SUPPLIES_ORDER_NOTES);
  const q = query(colRef, where('orderId', '==', id));

  try {
    const querySnapshot = await getDocs(q);
    const notes = querySnapshot.docs.map(doc => doc.data());

    if (notes.length === 0) {
      notes.push({ message: 'No Available Notes' });
    }
    onSuccess && onSuccess(notes);
  } catch (error) {
    onError && onError(error);
  }
}

export async function updateSupplyItemsStatus(id, newStatus, itemId, onSuccess, onError) {
  const currentUserId = auth.currentUser.uid;
  const colRef = collection(firebase, COLLECTIONS.SUPPLIES_ORDER);

  try {
    const docRef = doc(colRef, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      let updatedSupplyItems;

      if (Array.isArray(itemId)) {
        updatedSupplyItems = data.supplyItems.map(item => {
          if (itemId.includes(item.id)) {
            return {
              ...item,
              status: newStatus
            };
          }
          return item;
        });
      } else if (itemId) {
        updatedSupplyItems = data.supplyItems.map(item => {
          if (item.id === itemId) {
            return {
              ...item,
              status: newStatus
            };
          }
          return item;
        });
      } else {
        updatedSupplyItems = data.supplyItems.map(item => ({
          ...item,
          status: newStatus
        }));
      }

      const now = serverTimestamp();

      await updateDoc(docRef, {
        supplyItems: updatedSupplyItems,
        updateBy: currentUserId,
        updatedAt: now
      });

      onSuccess && onSuccess({ id: docSnap.id, ...data, supplyItems: updatedSupplyItems, updatedAt: now });
    } else {
      onError && onError("No such document!");
    }
  } catch (error) {
    onError && onError(error);
  }
}

export async function updateSupplyItemDate(id, itemId, date, onSuccess, onError) {
  const currentUserId = auth.currentUser.uid;
  const colRef = collection(firebase, COLLECTIONS.SUPPLIES_ORDER);

  try {
    const docRef = doc(colRef, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const updatedSupplyItems = data.supplyItems.map(item => {
        if (item.id === itemId) {
          const timestamp = Timestamp.fromDate(new Date(date));
          return { ...item, deliveryDate: timestamp };
        }
        return item;
      });

      const now = serverTimestamp();

      await updateDoc(docRef, {
        supplyItems: updatedSupplyItems,
        updatedBy: currentUserId,
        updatedAt: now
      });

      onSuccess && onSuccess({ id: docSnap.id, ...data, supplyItems: updatedSupplyItems, updatedAt: now });
    } else {
      onError && onError("No such document!");
    }
  } catch (error) {
    console.error("Error updating supply item date:", error);
    onError && onError(error);
  }
}


export async function supplyRejectReasons(id, result, onSuccess, onError) {
  const currentUserId = auth.currentUser.uid;

  if (!id || id.trim() === "") {
    onError && onError({ status: false, message: "Id is Missing" });
    return;
  }

  try {
    const colRef = collection(firebase, COLLECTIONS.SUPPLIES_ORDER);
    const docRef = doc(colRef, id);

    const newReason = {
      reason: result.reason,
      message: result.message,
    };

    await updateDoc(docRef, {
      rejectReason: arrayUnion(newReason),
      updateBy: currentUserId,
      updatedAt: serverTimestamp(),
    });

    onSuccess && onSuccess({ status: true });
  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, message: error.message });
  }
}

export async function updateArchive(id, formData, onSuccess, onError) {
  const currentUserId = auth.currentUser?.uid;

  if (!id || id.trim() === "") {
    onError && onError({ status: false, message: "Id is Missing" });
    return;
  }

  try {
    const updateData = {
      ...formData,
      updateBy: currentUserId,
      updatedAt: serverTimestamp(),
    };

    const docRef = doc(firebase, COLLECTIONS.SUPPLIES_ORDER, id);
    await setDoc(docRef, updateData, { merge: true });

    onSuccess && onSuccess({ status: true, data: formData });
  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, message: error.message });
  }
}

export async function UpdateMsgFalse(formData, onSuccess, onError) {
  const suppliesDocRef = doc(firebase, COLLECTIONS.SUPPLIES_ORDER, formData.id);

  try {
    const suppliesDocSnapshot = await getDoc(suppliesDocRef);

    if (suppliesDocSnapshot.exists()) {
      await updateDoc(suppliesDocRef, {
        msg: false,
        updatedAt: serverTimestamp(),
      });

      if (onSuccess && typeof onSuccess === "function") {
        onSuccess({
          status: true,
          data: { id: formData.id, msg: false, updatedAt: new Date() },
        });
      }
    } else {
      throw new Error(`No document found with ID: ${formData.id}`);
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError({ status: false, error: error.message || error });
    }
  }
}