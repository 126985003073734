import React, { useEffect, useState } from "react";
import { getFormatedDate } from "../../services/helpers";

const NotificationDetails = (props) => {
  const [itemdata, setItemdata] = useState({});

  useEffect(() => {
    setItemdata(props.item);
  }, [props.item]);
  const emplist = itemdata?.Employee ? itemdata?.Employee?.map((eitem) => eitem.name) : [];
  const empliststring = emplist.join(', ')
  const getClientNames = (clientData) => {
    if (Array.isArray(clientData)) {
      return clientData.map((item) => item.name).filter(Boolean);
    } else if (clientData && typeof clientData === 'object' && clientData.name) {
      return [clientData.name];
    }
    return [];
  };
  
  const clientList = getClientNames(itemdata?.Client);
  const clientString = clientList.join(', ');
  return (
    <div className="pb-15 ph-15">
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Date: </span>
         <span className="updateDialogLabel">{getFormatedDate(itemdata?.createdOn)}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Group: </span> 
        <span className="updateDialogLabel">{itemdata?.Group?.title}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Method: </span> 
        <span className="updateDialogLabel">{Array.isArray(itemdata?.Type) ? itemdata?.Type.join(', ') :
          itemdata?.Type === "Push" ? "SMS" :
          itemdata?.Type === "PushNotification" ? "Push Notification" :
          itemdata?.Type === "Email" ? "Email" :
          itemdata?.Type === "Both" ? "All (Push Notification, Email, SMS)" :
          itemdata.Type
        }</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Client: </span> 
        <span className="updateDialogLabel">{clientString}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Message: </span> 
        <span className="nl2br updateDialogLabel">{itemdata?.Message}</span>
      </div>
      <div className="updateDialog pv-5">
        <span className="updateDialogName">Employees / Receipents: </span> 
        <span className="updateDialogLabel">{empliststring}</span>
      </div>
    </div>
  );
};
export default NotificationDetails;
