import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
} from "@material-ui/core";

import { getRandomNumber } from "../../services/helpers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  selectinput: {
    padding: "8px 10px"
  },
  selectlabel: {
    transform: "translate(14px, 9px) scale(1)"
  },
  selectoption: {
    margin: 0
  },
  selectinputDatetime: {
    padding: "12px 10px",
  },
  selectlabelDatetime: {
    transform: "translate(14px, 15px) scale(1)",
  },
  grayOption: {
    color: "#9fa6b3",
  },
}));

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  className: "custom-select-menu",
};

export default function SingleSelectem(props) {
  const randomNumber = getRandomNumber();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderOption = (opItem, index) => {
    const employee = props?.inactive
      ? props.allEmployees?.data?.find((emp) => emp.id === opItem.value)
      : null;
    const isInactive = employee && employee.status === false;
    const isInactiveLabel = opItem?.label === "Inactive Employee";

    const handleItemClick = (event) => {
      if (isInactiveLabel) {
        props.onChange({
          target: {
            value: opItem?.value,
          },
        });
        handleOpen()
      } else if (props?.onChange) {
        props.onChange({
          target: {
            value: opItem?.value,
          },
        });
        handleClose(); 
      }
    };

    return (
      <MenuItem
        key={opItem?.label}
        value={opItem?.value}
        className={isInactive ? classes.grayOption : ""}
        onClick={(event) => handleItemClick(event, opItem)}
      >
        <ListItemText
          className={classes.selectoption}
          primary={
            isInactiveLabel ? (
              <React.Fragment>
                <span
                  style={{
                    fontStyle: "italic",
                    color: "#9fa6b3",
                  }}
                >
                  {opItem?.label}
                </span>
                <span
                  style={{
                    color: "#078037",
                    marginLeft: "8px",
                  }}
                >
                  &gt;
                </span>
              </React.Fragment>
            ) : (
              opItem?.label
            )
          }
        />
      </MenuItem>
    );
  };

  return (
    <>
    <FormControl variant="outlined" className={classes.formControl}>
      {typeof props?.label !== "undefined" && props?.label !== "" && (
        <InputLabel
          className={
            props.datetime ? classes.selectlabelDatetime : classes.selectlabel
          }
          id={randomNumber + "demo-select-label"}
        >
          {props?.label}
        </InputLabel>
      )}
        <Select
          labelId={randomNumber + "demo-selectlabel"}
          id={randomNumber + "demo-select"}
          // onChange={props?.onChange}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          label={
            props?.label !== "undefined" && props?.label !== ""
              ? props?.label
              : ""
          }
          value={props?.value ? props?.value : ''}
          MenuProps={MenuProps}
          disabled={props?.disabled ? props?.disabled : false}
          inputProps={{
            name: props?.name,
          className: `${
            props.datetime
              ? classes.selectinputDatetime
              : classes.selectinput
          } ${props?.className || ""}`,
        }}
        >
          {props.options?.length > 0 &&
            props.options?.map((opItem, index) => renderOption(opItem, index))}
        </Select>
      </FormControl>
    </>
  );
}
