import React from "react";
import { confirmable } from "react-confirm";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "4px 8px",
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    fontSize: "22px",
    fontWeight: "800",
    // flex: "1",
    paddingLeft: "3px",
    color:"#3d3d3d",
    padding:"5px 10px 5px 5px",
    marginLeft:"12px"
  },
  closeButton: {
    // position: "absolute",
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    color: "red",
    marginLeft: "10px"
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, extraClasses, ...other } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding:"2px" }}>
      <Typography variant="body" className={`${classes.dialogTitle} mt-20`}>
        {children}
      </Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="small">
       <CloseIcon/>
       </IconButton>
    </div>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  dividers: {
    borderColor: theme.palette.secondaryBorder,
    borderBottom: "none",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class ConfirmationNew extends React.Component {
  render() {
    const {
      okLabel = "OK",
      cancelLabel = "Cancel",
      title,
      titleWrapClass,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      modal,
    } = this.props;

    return (
      <div>
        <Dialog
          open={show}
          className="thameSecondaryDialog"
          maxWidth={"md"}
          modal={modal}
        >
          <DialogTitle extraClasses={titleWrapClass ? titleWrapClass : ''} id="customized-dialog-title" onClose={dismiss}>
            {title || "CONFIRMATION"}
          </DialogTitle>
          <Divider className="mt-15"/>
          <DialogContent className="thameDialogContent" style={{ padding: "18px", fontSize:"16px" }}>
            {confirmation}
          </DialogContent>
          {(proceed || cancel) && (
            <DialogActions>
              {proceed && (
                <Button autoFocus onClick={() => proceed(true)} style={{backgroundColor:"green", color:"white"}} variant="contained">
                  {okLabel ? okLabel : "Yes"}
                </Button>
              )}
              {cancel && (
                <Button autoFocus onClick={() => proceed(false)} style={{backgroundColor:"gray", color:"white"}} variant="contained">
                  {cancelLabel ? cancelLabel : "Cancel"}
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default confirmable(ConfirmationNew);
