/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthContext from "../hooks/authContext";
import AppRoutes from "./app-route";
import theme from "../theme";
import Loader from "../components/AppLoader/Loader";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { setLoginToken, clearLoginToken } from "../services/storage";
import { EventEmitter } from "../services/event.service";
import { getAuth, onAuthStateChanged } from "firebase/auth"; 
import { logOut, getCurrentUser } from "../services/firebase";
import { getSuppliesListCount, getSupplyOrderList } from "../services/apiservices/supplies";
import { getIssueListCount } from "../services/apiservices/issues";

function App(props) {
  const [authstatus, setauthstatus] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentChannel, setCurrentChannel] = useState();
  const [isReadPublic, setIsReadPublic] = useState();
  const [isReadPrivate, setIsReadPrivate] = useState();
  const [supplieStatusCount, setSupplieStatusCount] = useState({Pending:0, OPEN:0});
  const [pendingSupplyStatus, setPendingSupplyStatus] = useState([]);
  const [highPrioritySupply, setHighPrioritySupply] = useState([]);
  const [appointmentsData, setAppointmentsData] = useState({})
  const [editAppointmentData, setEditAppointmentData] = useState({})
  const [updateAppointmentData, setUpdateAppointmentData] = useState({})
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issueCount, setIssueCount] = useState([]);
  const [newClientId, setNewClientId] = useState();
  const [newEmpId, setNewEmpId] = useState();
  const [tableState, setTableState] = useState({
    sort: false,
    rowsPerPage: 10,
  });

  const getSupplyCount = async() => {
    await getSuppliesListCount(
      {
        status: ["Pending"],
      }, 
      (res) => {
        if(res[0]?.Pending === 0){
          setPendingSupplyStatus([]);
        } else {
          setPendingSupplyStatus(res);
        }
        
        setSupplieStatusCount(res[0]);
      }
    );
  }

  const getIssueCount = async() =>{
    await getIssueListCount(
      {
        status: ["Pending"],
      },
      (res) => {
        setIssueCount(res);
      }
    )
  }

  const getHighPrioritySupply = async() =>{
    await getSupplyOrderList(
      {},
      (res) => {
        let records = res.filter(order => order.status !== "Pending" && order.status !== "Rejected");
        let filterRecords = records.filter(order => order.status !== "Delivered" || (order.msg === true && !order.archive));
        setHighPrioritySupply(filterRecords);
      }
    )
  }

  useEffect(() => {
    login();
  }, []);

  const resetPendingSupplyStatus = () => setPendingSupplyStatus([]);
  const resetHighPrioritySupply = () => setHighPrioritySupply([]);
  const resetIssueCount = () => setIssueCount({});

  const login = async (token) => {
    setIsLoading(true);
    getSupplyCount();
    getHighPrioritySupply();
    getIssueCount();
  
    const auth = getAuth();
    return onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = auth.currentUser;
        let currentuser = await getCurrentUser();
  
        if (
          Object.keys(currentuser?.userData).length !== 0 &&
          currentuser?.userData.constructor === Object
        ) {
          setLoginToken(currentuser?.token);
          
          setUserProfile({ ...currentuser?.userData, lastLogin: userData?.metadata?.lastSignInTime });
          setauthstatus(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setauthstatus(false);
          clearLoginToken();
          logOut();
        }
      } else {
        setauthstatus(false);
        setIsLoading(false);
      }
    });
  };
  const logoutuser = () => {
    setauthstatus(false);
    clearLoginToken();
    logOut();
  };
  EventEmitter.subscribe("logoutUser", logoutuser);

  return (
    <AuthContext.Provider
      value={{
        status: authstatus,
        userProfile: userProfile,
        login: login,
        currentChannel: currentChannel,
        setCurrentChannel: setCurrentChannel,
        isReadPrivate: isReadPrivate,
        isReadPublic:isReadPublic,
        setIsReadPrivate: setIsReadPrivate,
        setIsReadPublic: setIsReadPublic,
        logoutuser: logoutuser,
        pendingSupplyStatus: pendingSupplyStatus,
        highPrioritySupply: highPrioritySupply,
        setPendingSupplyStatus: setPendingSupplyStatus,
        issueCount: issueCount,
        setIssueCount: setIssueCount,
        isLoading: isLoading,
        resetPendingSupplyStatus,
        resetHighPrioritySupply,
        resetIssueCount,
        tableState,
        setTableState,
        setEditAppointmentData,
        setUpdateAppointmentData,
        updateAppointmentData,
        editAppointmentData,
        setAppointmentsData,
        appointmentsData,
        setSelectedAppointment,
        selectedAppointment,
        setIsModalOpen,
        isModalOpen,
        setIsExpanded,
        setNewClientId,
        newClientId,
        setNewEmpId,
        newEmpId,
        isExpanded,
        supplieStatusCount
      }}
    >
      <ThemeProvider theme={theme}>
        {isLoading === true && <Loader />}
        <CssBaseline />
        <Router>{isLoading !== true && <AppRoutes {...props} />}</Router>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
