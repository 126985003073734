import { Button } from "@material-ui/core";
import React from "react";

const ConfirmCancel = (props) => {
  const onOkay = () => {
    props?.onCancelAll();
    props?.onClose();
  }
  return (
    <div className="ph-12">
      <div>{props?.isEdit === true ? "Data Edited will not be saved.":"All Filled Fields will be cleared."}</div>
      <div className="mv-12 text-right">
        <Button
          className="ph-30 mb-15" 
          style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }} 
          variant="contained"
          type="button"
          onClick={onOkay}
        >
          Yes
        </Button>
        <Button
          className="ph-30 mb-15 ml-10" 
          style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }} 
          variant="contained"
          type="button"
          onClick={props?.onClose}
        >
          No
        </Button>
      </div>
    </div>
  );
};
export default ConfirmCancel;
