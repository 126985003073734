/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import { Button, Container, Tooltip } from "@material-ui/core";
import { getAllEmployee } from "../../services/apiservices/employee";
import SingleSelect from "../../components/SelectField/SingleSelect";
import { DeleteAppointment, getAllAppoinment } from "../../services/apiservices/appoinments";
import { getClientsList } from "../../services/apiservices/client";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import AuthContext from "../../hooks/authContext";
import { DeleteApptDialog, SuccessDeleteApptDialog } from "../../components/DialogComponent/SupplyDataDialog";
import { AppointmentModal, AppointmentsDetailComponent } from "./Models/AppointmentModel";
import MonthView from "./Calendar/MonthViews";
import WeekView from "./Calendar/WeekViews";
import NavBar from "./Calendar/NavBar/Navigations";
import DayView from "./Calendar/DayViews";
import NorthWestIcon from '@mui/icons-material/NorthWest';
import commonContext from "../../hooks/commonContext";
import SingleSelectem from "../../components/SelectField/SingleSelectem";

const useStyles = makeStyles({
  customTooltip: {
    fontSize: '12px',
    padding: '5px',
    top: "-20px",
    width: "80px",
    left: "20px",
  },
});

const ManageAppointments = () => {
  const [empNames, setEmpNames] = useState("All");
  const [employeeNameList, setEmployeeNameList] = useState([
    { label: "All", value: "All" },
  ]);
  const [clientNameList, setClientNameList] = useState([]);
  const [clientDetails, setClientDetails] = useState();
  const [clientName, setClientName] = useState("All");
  const [employeeDetails, setEmployeeDetails] = useState();
  const [inactiveEmployee, setInactiveEmployee] = useState();
  const [allEmployees, setAllEmployees] = useState();
  const [appoinments, setAppoinments] = useState([]);
  let navigate = useNavigate();
  const [upcomingAppointments, setUpcomingAppointments] = useState({});
  const isMobile = useMediaQuery("(max-width:767px)");
  const isLandscape = useMediaQuery(
    "(min-width: 771px) and (max-width: 940px)"
  );
  const [filteredAppointment, setFilteredAppointments] = useState([]);
  const [cancelAppt, setCancelAppt] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const auth = useContext(AuthContext);
  const [view, setView] = React.useState('month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [weekDates, setWeekDates] = useState([]);
  const [selectedDayDate, setSelectedDayDate] = useState();
  const editAppointmentData = auth?.editAppointmentData?.selectedAppointment;
  const appointmentRefs = useRef({});
  const isModalOpen = auth.isModalOpen;
  const setIsModalOpen = auth.setIsModalOpen;
  const selectedAppointment = auth.selectedAppointment;
  const setSelectedAppointment = auth.setSelectedAppointment;
  const classes = useStyles();
  const CommonContext = useContext(commonContext)

  const ManageAppointmentsData = async () => {
    CommonContext?.setLoader(true);

    try {
      const [appointmentResponse, clientsResponse, employeesResponse] = await Promise.all([
        getAllAppoinment(),
        new Promise((resolve) => getClientsList({}, (res) => resolve(res || []))),
        new Promise((resolve) => getAllEmployee((res) => resolve(res || {}))),
      ]);

      if (appointmentResponse) {
        const filteredAppointments = appointmentResponse?.appointments.filter(item => !item?.canceled);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const filterAppointments = filteredAppointments.filter((appointment) => {
          const appointmentDateTime = new Date(appointment.appointmentDateTime);
          const appointmentDate = new Date(
            appointmentDateTime.getFullYear(),
            appointmentDateTime.getMonth(),
            appointmentDateTime.getDate()
          );

          const isPastOrToday = appointmentDate <= today;
          const user = employeesResponse.data.find((item) => item.role === "Employee" && item.userId === appointment.userId);

          if (user && (user.status === false || user.deleted)) {
            return isPastOrToday;
          }
          return true;
        });

        setAppoinments(filterAppointments);
        auth.setAppointmentsData(filterAppointments);
  
        const upcomingAppointments = filterAppointments.sort((a, b) => {
          const dateCompare = a.apoointmentDetails.date.localeCompare(b.apoointmentDetails.date);
          return dateCompare !== 0
            ? dateCompare
            : a.apoointmentDetails.time.localeCompare(b.apoointmentDetails.time);
        });
  
        const groupedByDate = upcomingAppointments.reduce((grouped, appointment) => {
          const { date } = appointment.apoointmentDetails;
          if (!grouped[date]) grouped[date] = [];
          grouped[date].push(appointment);
          return grouped;
        }, {});
  
        setUpcomingAppointments(groupedByDate);
  
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();

        const filteredByMonth = Object.entries(groupedByDate).reduce((result, [date, appointments]) => {
          const dateObj = new Date(date);
          if (dateObj.getMonth() === currentMonth && dateObj.getFullYear() === currentYear) {
            result[date] = appointments;
          }
          return result;
        }, {});
  
        setFilteredAppointments(filteredByMonth);
      } else {
        setAppoinments([]);
        auth.setAppointmentsData([]);
        setUpcomingAppointments({});
        setFilteredAppointments([]);
      }

      if (clientsResponse.length > 0) {
        const appointmentClientIds = appointmentResponse.appointments.map((appointment) => appointment?.clientId);
        const clientData = clientsResponse.filter((client) =>
          appointmentClientIds.includes(String(client.id))
        );
        setClientDetails(clientData);
        const clientNames = clientData.map(client => ({
          value: client?.id,
          label: client?.clientName,
          firstName: client?.firstName,
          lastName: client?.lastName,
        }));
        setClientNameList([{ label: "All", value: "All" }, ...clientNames]);
      } else {
        setClientDetails([]);
        setClientNameList([]);
      }

      if (employeesResponse?.data) {
        const appointmentEmpIds = appointmentResponse.appointments.map((appointment) => appointment?.userId);
        const empData = employeesResponse?.data.filter((emp) =>
          appointmentEmpIds.includes(String(emp?.userId))
        );
        setAllEmployees(employeesResponse);
        const employeeList = empData.filter(item => item.role === "Employee" && (item.status !== false && !item.deleted));
        const InactiveEmp = empData.filter(item => item.role === "Employee" && (item.status === false || item.deleted));
        setEmployeeDetails(employeeList);
        setInactiveEmployee(InactiveEmp);

        const employeeNames = employeeList.map(emp => ({
          value: emp?.id,
          client: emp?.clients,
          label: `${emp?.firstName} ${emp?.lastName}`,
          email: emp?.email,
        }));
        setEmployeeNameList([{ label: "All", value: "All" }, ...employeeNames, { label: "Inactive Employee", value: "Inactive Employee" }]);
      } else {
        setAllEmployees([]);
        setEmployeeDetails([]);
        setEmployeeNameList([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      setAppoinments([]);
      auth.setAppointmentsData([]);
      setUpcomingAppointments({});
      setFilteredAppointments([]);
      setClientDetails([]);
      setClientNameList([]);
      setAllEmployees([]);
      setEmployeeDetails([]);
      setEmployeeNameList([]);
    }

    CommonContext?.setLoader(false);
  };

  useEffect(() => {
    ManageAppointmentsData();
  }, []);

  const handleEmpNameChange = (e) => {
    const selectEmployee = e.target.value;
    setEmpNames(selectEmployee);

    if (selectEmployee === "Inactive Employee") {
      const inactive = employeeNameList.filter((item => inactiveEmployee.some(inac => inac.id === item.value)))
      if (inactive.length > 0) {
        setEmployeeNameList((prevList) => {
          const updatedList = [{ label: "All", value: "All" }];

          const activeEmployeeNames = employeeDetails
            .filter(emp => !inactiveEmployee.some(inactiveEmp => inactiveEmp.id === emp.id))
            .map(emp => ({
              value: emp?.id,
              client: emp?.clients,
              label: `${emp?.firstName} ${emp?.lastName}`,
              email: emp?.email,
            }));

          updatedList.push(...activeEmployeeNames);

          updatedList.push({ label: "Inactive Employee", value: "Inactive Employee" });

          return updatedList;
        });
      } else {
        const employeeNames = inactiveEmployee.map(emp => ({
          value: emp?.id,
          client: emp?.clients,
          label: `${emp?.firstName} ${emp?.lastName}`,
          email: emp?.email,
        }));

        setEmployeeNameList((prevList) => {
          const updatedList = [...prevList];

          employeeNames.forEach(inactiveEmp => {
            if (!prevList.some(emp => emp.value === inactiveEmp.value)) {
              updatedList.push(inactiveEmp);
            }
          });

          return updatedList;
        });
      }
    } else {
      const isInactive = inactiveEmployee.some(emp => emp.id === selectEmployee);

      if (!isInactive) {
        setEmployeeNameList((prevList) => {
          const updatedList = [{ label: "All", value: "All" }];

          const activeEmployeeNames = employeeDetails
            .filter(emp => !inactiveEmployee.some(inactiveEmp => inactiveEmp.id === emp.id))
            .map(emp => ({
              value: emp?.id,
              client: emp?.clients,
              label: `${emp?.firstName} ${emp?.lastName}`,
              email: emp?.email,
            }));

          updatedList.push(...activeEmployeeNames);

          updatedList.push({ label: "Inactive Employee", value: "Inactive Employee" });

          return updatedList;
        });
      }
    }
  };

  const handelClientNameChange = (e) => {
    const selectClient = e.target.value;
    setClientName(selectClient);
  };

  const handelCancelCloseDialog = () => {
    setCancelAppt(false);
  }

  const handelSingleOrSeriesApptCancelDialog = async () => {
    const ids = editAppointmentData?.id;
    setCancelAppt(false);
    const response = await DeleteAppointment(ids);
    if (response.success) {
      setIsDeleteDialogOpen(true);
    } else {
      console.error("Failed to save appointment:", response.error);
    }
  }

  const handelSuccelDeleteCloseDialog = async () => {
    setIsDeleteDialogOpen(false);
    setIsModalOpen(false);
    setCancelAppt(false);
    auth.setEditAppointmentData({});
    auth.setUpdateAppointmentData({});
    ManageAppointmentsData();
  };


  const redirectToSchedule = () => {
    auth.setIsExpanded(false);
    navigate("/appoinmentSchedule");
  };

  const handleScrollToAppointments = (date) => {
    const dateKey = date.format("MMMM D, YYYY");
    const targetRef = appointmentRefs.current[dateKey];
    if (targetRef) {
      targetRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleAppointmentOpen = (appointment) => {
    setIsModalOpen(true);
    setSelectedAppointment(appointment);
  };

  return (
    <>
      <Container className={`${auth.isExpanded ? "containers" : ""}`}>
        <PageTitle
          pageTitle="View Schedule"
          showBreadcrumb={[
            { label: "Home", to: "/", onclick: () => auth.setIsExpanded(false) },
            { label: "Appoinments" },
          ]}
          BreadCrumbCurrentPageTitle={"View Schedule"}
          showLastSeen={true}
        />
        <div className="sectionBox">
          <div className="d-flex flex-space-between">
            {!isMobile && (
              <div style={{ margin: "-10px 0px 0px -10px" }}>
                <Tooltip title={auth.isExpanded ? "Show Menu" : "Hide Menu"} arrow classes={{ tooltip: classes.customTooltip }}>
                  <div
                    style={{
                      width: "80px",
                      height: "60px",
                      position: "relative",
                      cursor: "pointer",
                      right: "19px",
                      top: "-9px"
                    }}
                    onClick={() => auth.setIsExpanded(!auth.isExpanded)}
                  >
                    <svg
                      viewBox="0 0 100 100"
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                      }}
                    >
                      <polygon points="100,0 0,100 0,0" fill="#eaeaea" />
                    </svg>
                    <NorthWestIcon fontSize="large"
                      style={{
                        position: "absolute",
                        left: "10%",
                        transform: auth.isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease-in-out",
                        color: "#000",
                      }} />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="d-flex flex-direction-column mb-15">
            {isMobile ? (
              <>
                <div className="mb-20 d-flex flex-justify-flexend">
                  <Button
                    variant="contained"
                    className={`${isMobile || isLandscape ? "pv-10 ph-10" : "pv-10 ph-20"
                      }`}
                    style={{ backgroundColor: "green", color: "white" }}
                    onClick={redirectToSchedule}
                  >
                    Schedule Now
                  </Button>
                </div>
                <div style={{ width: isMobile || isLandscape ? "390px" : "300px" }} className="mb-20">
                  <SingleSelectem
                    value={empNames}
                    name={"employeeName"}
                    label={"Employee Name"}
                    onChange={handleEmpNameChange}
                    options={employeeNameList}
                    datetime={true}
                    inactive={true}
                    allEmployees={allEmployees}
                  />
                </div>
                <div className="mb-20" style={{ width: isMobile ? "390px" : "300px" }}>
                  <SingleSelect
                    value={clientName}
                    name={"clientName"}
                    label={"Client Name"}
                    onChange={handelClientNameChange}
                    options={clientNameList}
                    datetime={true}
                  />
                </div>
              </>
            ) : null}
          </div>

          {!isMobile ?
            <div style={{ position: "relative" }}>
              <div className="d-flex flex-justify-end mb-20">
                <div className="ml-20" style={{ position: "sticky", right: "20px" }}>
                  <Button
                    variant="contained"
                    className={`${isMobile ? "pv-10 ph-10" : "pv-10 ph-20"
                      }`}
                    style={{ backgroundColor: "green", color: "white" }}
                    onClick={redirectToSchedule}
                  >
                    Schedule Now
                  </Button>
                </div>
              </div>
            </div>
            : null}

          <NavBar
            empNames={empNames}
            handleEmpNameChange={handleEmpNameChange}
            employeeNameList={employeeNameList}
            setFilteredAppointments={setFilteredAppointments}
            upcomingAppointments={upcomingAppointments}
            setView={setView}
            view={view}
            setCurrentDate={setCurrentDate}
            currentDate={currentDate}
            weekDates={weekDates}
            setWeekDates={setWeekDates}
            setSelectedDayDate={setSelectedDayDate}
            selectedDayDate={selectedDayDate}
            clientNameList={clientNameList}
            clientName={clientName}
            handelClientNameChange={handelClientNameChange}
            allEmployees={allEmployees}
          />

          {view === 'month' && (
            <MonthView
              employeeNameList={allEmployees}
              handleScrollToAppointments={handleScrollToAppointments}
              appointmentRefs={appointmentRefs}
              currentDate={currentDate}
              empNames={empNames}
              clientName={clientName}
            />
          )}

          {view === 'week' && (
            <WeekView
              weeksAllDays={weekDates}
              clientDetails={clientDetails}
              empNames={empNames}
              clientName={clientName}
              ManageAppointmentsData={ManageAppointmentsData}
                employeeDetails={allEmployees}
            />
          )}

          {view === 'day' && (
            <DayView
              allDays={selectedDayDate}
              clientDetails={clientDetails}
              empNames={empNames}
              clientName={clientName}
              employeeDetails={allEmployees}
              ManageAppointmentsData={ManageAppointmentsData}
            />
          )}

          <div className="mt-40">
            <AppointmentsDetailComponent
              appointments={filteredAppointment}
              handleAppointmentOpen={handleAppointmentOpen}
              appointmentRefs={appointmentRefs}
              empNames={empNames}
              clientName={clientName}
            />
          </div>
          {selectedAppointment && (
            <ScheduleModal
              isModalOpen={isModalOpen}
              selectedAppointment={selectedAppointment}
              isMobile={isMobile}
              isLandscape={isLandscape}
              clientDetails={clientDetails}
              employeeDetails={allEmployees}
              appoinments={appoinments}
              auth={auth}
              setIsModalOpen={setIsModalOpen}
              setCancelAppt={setCancelAppt}
            />
          )}
        </div>
      </Container>

      {cancelAppt && (
        <DeleteApptDialog open={cancelAppt} client={clientDetails} onConfirm={handelSingleOrSeriesApptCancelDialog}
          auth={editAppointmentData} onClose={handelCancelCloseDialog} />
      )}
      {isDeleteDialogOpen && (
        <SuccessDeleteApptDialog open={isDeleteDialogOpen} onClose={handelSuccelDeleteCloseDialog} />
      )}
    </>
  );
};

const ScheduleModal = (props) => {
  const { isModalOpen } = props;
  const [isModalO, setIsModalO] = useState(false);


  useEffect(() => {
    if (isModalOpen) {
      setIsModalO(isModalOpen);
    } else {
      setTimeout(() => {
        setIsModalO(isModalOpen);
      }, 250);
    }

  }, [isModalOpen]);

  const handleOverlayClick = (e) => {
    if (e.target?.classList?.contains('modal-overlay')) {
      props?.setIsModalOpen(false);
      props?.auth.setEditAppointmentData({});
      props?.auth.setUpdateAppointmentData({});
    }
  };

  return isModalO ? (
    <div className={`modal-overlay active`} onClick={handleOverlayClick} >
      <div className={`modal-content ${isModalOpen ? 'slideUpModal' : 'slideDownModal'}`}>
        {" "}
        <AppointmentModal {...props} />{" "}
      </div>
    </div>
  ) : null;
};
export default ManageAppointments;
