/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../hooks/commonContext";
import { Container, TextField, IconButton, Dialog, Typography, Divider, DialogContent, DialogActions, Button } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns, ColumnsRes } from "./employeeTableColumns";
import TableToolbar from "../../components/Table/TableToolbar";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import { useEffect } from "react";
import {
    getEmployeesList,
    updateEmployee,
} from "../../services/apiservices/employee";
import { useMediaQuery } from "@mui/material";
import ManagePagesCommonCard from "../../components/ResponsiveCardComponent/ManageListPages/ManageListPagesResponsive";
import CloseIcon from '@mui/icons-material/Close';

const ConfirmDiaogStatus = ({ open, onConfirm, onClose, rowitem }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <div className="d-flex flex-center flex-space-between">
                <Typography variant="body" className="mt-20 dialogCancelTitle">
                    Active Employee
                </Typography>
                <IconButton style={{ color: "red", marginLeft: "10px" }} onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </div>
            <Divider className="mt-15" />
            <DialogContent className="mb-10">
                <span><strong style={{ fontWeight: "600" }}>{rowitem.firstName} {rowitem.lastName}</strong> is inactive right now. Do you want to activate this user?</span></DialogContent>
            <DialogActions className="mt-20 mb-15">
                <Button variant="contained" onClick={onConfirm} className="ph-35 mr-5" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
                    Yes
                </Button>
                <Button variant="contained" onClick={onClose} className="ph-35 mr-10" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
                    No
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const InactiveEmployee = (props) => {
    const commonContext = useContext(CommonContext);
    const navigate = useNavigate();
    const [allEmployees, setAllEmployees] = React.useState([]);
    const [searchTerm, setsearchTerm] = React.useState("");
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [expanded, setExpanded] = React.useState(null);
    const [data, setData] = React.useState({});
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const fetchEmployeesList = async () => {
        setIsLoading(true);
        getEmployeesList(
            {},
            (res) => {
                if (res) {
                    const data = res.filter((item) => item.deleted || item.status === false);
                    let allEmp = data?.map((eitem) => {
                        return {
                            ...eitem,
                            userName: `${eitem?.firstName} ${eitem?.lastName}`,
                        };
                    });
                    setAllEmployees(allEmp);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            },
            (resError) => {
                setIsLoading(false);
            }
        );
    };
    useEffect(() => {
        fetchEmployeesList();
    }, []);

    const redirectToCreateEmp = (employeeId) => {
        if (typeof employeeId !== "undefined" && employeeId !== "")
            navigate("/createEmp/" + employeeId);
        else navigate("/createEmp");
    };

    const loginWithEmployee = (empDetails) => {
        navigate("/ViewEmployeeApp/" + empDetails?.id);
    }

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "email":
                return (
                    <>
                        <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${rcellItem[hcellItem?.id]}`} rel="noopener noreferrer" target="_blank" className="resLinks" style={{ overflowWrap: "anywhere" }}>
                            {rcellItem[hcellItem?.id]}
                        </a>
                    </>
                );
            case "action":
                return (
                    <>
                        {rcellItem?.role === 'Employee' ?
                            <IconButton
                                aria-label="Login"
                                color="primary"
                                size="small"
                                onClick={() => loginWithEmployee(rcellItem)}
                            >
                                <LockIcon />
                            </IconButton> : null}
                        <IconButton
                            aria-label="Edit"
                            color="primary"
                            size="small"
                            onClick={() => redirectToCreateEmp(rcellItem?.id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </>
                );

            default:
                return rcellItem[hcellItem?.id];
        }
    };

    const tableRightComponent = () => {
        return (
            (isMobile || isLandscape) ? (
                <div className="d-flex flex-direction-column pl-2">
                    <div className="w320">
                        <TextField
                            fullWidth={true}
                            size="small"
                            name="searchTerm"
                            id="searchTerm"
                            label="Search"
                            variant="outlined"
                            placeholder="Search"
                            className="input customSearch mb-15"
                            onChange={(event) => {
                                setsearchTerm(event.target.value);
                            }}
                            value={searchTerm ? searchTerm : ""}
                        />
                    </div>
                </div>
            ) : (
                <div className="d-flex groupsearch">
                    <div className="mh-3">
                        <TextField
                            fullWidth={true}
                            size="small"
                            name="searchTerm"
                            id="searchTerm"
                            label=""
                            variant="outlined"
                            placeholder="Search Employee..."
                            className="input"
                            onChange={(event) => {
                                setsearchTerm(event.target.value);
                            }}
                            value={searchTerm ? searchTerm : ""}
                        />
                    </div>
                </div>
            )
        );
    };

    const handelOnConfirm = () => {
        setIsLoading(true);
        updateEmployee(
            data?.rowitem?.id,
            { status: data?.newstatus },
            (res) => {
                if (res?.status === true) {
                    setDialogOpen(false);
                    setIsLoading(false);
                    setData({});
                    navigate("/manageEmp");
                } else {
                    setIsLoading(false);
                    setDialogOpen(false);
                    setData({});
                }
            },
            (resError) => {
                setIsLoading(false);
                setDialogOpen(false);
                setData({});
                fetchEmployeesList();
            }
        );
    };

    const handelCloseDialog = () => {
        setDialogOpen(false);
        setData({});
        fetchEmployeesList();
    }

    const OnSwitchChange = (rowitem, newstatus) => {
        setDialogOpen(true);
        setData({
            rowitem: rowitem,
            newstatus: newstatus
        })
    };
    const checkRowSearch = (searchTerm, row) => {
        return (
            String(row.acuityId)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.email)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.userName)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.phoneNumber)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase()) ||
            String(row.employeerole)
                .toLowerCase()
                .includes(String(searchTerm).toLowerCase())
        );
    };
    const filteredList =
        searchTerm !== ""
            ? allEmployees?.filter((item) => checkRowSearch(searchTerm, item))
            : allEmployees;

    const handleExpandClick = (id) => {
        setExpanded(prevExpanded => (prevExpanded === id ? null : id));
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Employee"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Employee", to: "/" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Inactive Employee"}
                    showLastSeen={true}
                />

                <div className={`sectionBox2`}>
                    <TableToolbar
                        title={isMobile || isLandscape ? "" : "Employee"}
                        rightComponent={tableRightComponent()}
                    />
                    {(!isMobile && !isLandscape) &&
                        <ThemeTable
                            rows={filteredList}
                            headCells={Columns}
                            hidePagination={true}
                            isLoading={isLoading}
                            renderRowCell={renderRowCell}
                            OnSwitchChange={OnSwitchChange}
                        />
                    }
                    {(isMobile || isLandscape) && (
                        <>
                            {filteredList.map((record, index) => {
                                const isOpen = expanded === record?.id;
                                return (
                                    <ManagePagesCommonCard
                                        index={index}
                                        record={record}
                                        isOpen={isOpen}
                                        handleExpandClick={handleExpandClick}
                                        OnSwitchChange={OnSwitchChange}
                                        redirectToDetails={redirectToCreateEmp}
                                        loginWithEmployee={loginWithEmployee}
                                        columns={ColumnsRes}
                                        Employee={true}
                                    />
                                );
                            })}
                        </>
                    )}
                </div>
            </Container>
            {dialogOpen &&
                <ConfirmDiaogStatus open={dialogOpen} onConfirm={handelOnConfirm} onClose={handelCloseDialog} rowitem={data?.rowitem} />
            }
        </>
    );
};
export default InactiveEmployee;
