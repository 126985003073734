/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Grid,
  Container,
  TextareaAutosize,
  Button,
  Tooltip,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ModalImage from "react-modal-image";
import CircularProgress from "@mui/material/CircularProgress";

import Resizer from "react-image-file-resizer";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import PageTitle from "../../components/pageTitle/PageTitle";
import SendIcon from "@mui/icons-material/Send";
import PlusOneSharp from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import ClearIcon from "@mui/icons-material/Clear";

import {
  getChannelList,
  sendMessageToChannel,
  updateChannel,
  getMessages,
} from "../../services/apiservices/messages";

import AuthContext from "../../hooks/authContext";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import { getAllEmployee } from "../../services/apiservices/employee";

const ChatLeftPannel = ({ activeTab, isMobile, isLandscape }) => {
  const auth = useContext(AuthContext);

  const [pvtChannels, setPVTChannels] = React.useState([]);
  const [pubChannels, setPUBChannels] = React.useState([]);

  useEffect(() => {
    getChannelList(
      {},
      (res) => {
        if (res) {
          const pvtChn = [];
          const pubChn = [];
          let isReadPriv = true;
          let isReadPubl = true;
          res.forEach((element) => {
            if (element.type === "Private") {
              if (isReadPriv) {
                isReadPriv =
                  element?.readMsgUsers?.includes(auth.userProfile.id) ||
                  element?.id === auth.currentChannel?.id
                    ? true
                    : false;
              }
              pvtChn.push(element);
            } else {
              pubChn.push(element);
              if (isReadPubl) {
                isReadPubl =
                  element?.readMsgUsers?.includes(auth.userProfile.id) ||
                  element?.id === auth.currentChannel?.id
                    ? true
                    : false;
              }
            }
          });

          auth.setIsReadPublic(isReadPubl);
          auth.setIsReadPrivate(isReadPriv);
          setPUBChannels(pubChn);
          setPVTChannels(pvtChn);
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
    // return unsubscribe;
    // fetchChannelList();
  }, [auth]);
  const onChannelClickHandler = (channel) => {
    auth.setCurrentChannel(channel);
  };
  return isMobile || isLandscape ? (
    <div className="chatBoardContainer">
      {activeTab === "private" && (
        <div className="channelGroup">
          {pvtChannels.map((item) => (
            <ChanelItem
              key={item.id}
              item={item}
              currChannel={auth.currentChannel}
              currentUserId={auth.userProfile.id}
              onClickHandler={onChannelClickHandler}
              isMobile={isMobile}
              isLandscape={isLandscape}
            />
          ))}
        </div>
      )}

      {activeTab === "public" && (
        <div className="channelGroup msgbadge">
          {pubChannels.map((item) => (
            <ChanelItem
              key={item.id}
              item={item}
              currChannel={auth.currentChannel}
              currentUserId={auth.userProfile.id}
              onClickHandler={onChannelClickHandler}
              isMobile={isMobile}
              isLandscape={isLandscape}
            />
          ))}
        </div>
      )}
    </div>
  ) : (
    <>
      <Accordion className="chat-accordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="chanelHeader">Private Channel</div>
          {!auth.isReadPrivate && <div className="msgbadge"></div>}
        </AccordionSummary>
        <AccordionDetails>
          {pvtChannels.map((item, index) => (
            <ChanelItem
              currentUserId={auth.userProfile.id}
              key={item.id}
              item={item}
              currChannel={auth.currentChannel}
              onClickHandler={onChannelClickHandler}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion className="chat-accordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="chanelHeader">Public Channel</div>
          {!auth.isReadPublic && <div className="msgbadge"></div>}
        </AccordionSummary>
        <AccordionDetails>
          {pubChannels.map((item, index) => (
            <ChanelItem
              currentUserId={auth.userProfile.id}
              key={item.id}
              item={item}
              currChannel={auth.currentChannel}
              onClickHandler={onChannelClickHandler}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const ChanelItem = ({
  item,
  currChannel,
  onClickHandler,
  currentUserId,
  isMobile,
  isLandscape,
}) => {
  return (
    <div
      className={`channleTitleBox ${
        currChannel?.id === item.id ? "active" : ""
      } ${isMobile || isLandscape ? "mb-10" : ""}`}
      onClick={() => {
        onClickHandler(item);
      }}
    >
      <div className="channelTitle">{item.groupName}</div>
      <div className="channelMessage">
        {item?.last_message?.length > 40
          ? `${item?.last_message.substring(0, 40)}...`
          : item?.last_message}
      </div>
      {!item?.readMsgUsers?.includes(currentUserId) &&
      item?.id !== currChannel?.id ? (
        <div className="msgbadge"></div>
      ) : null}
    </div>
  );
};

const SendTextMessageBox = ({ currChannel, employee }) => {
  const imgRef = useRef(null);
  const auth = useContext(AuthContext);
  const [msgText, setMsgText] = useState("");
  const [currentImage, setCurrentImage] = useState();
  const [displayImage, setDisplayImage] = useState([]);
  // const [compressImage, setCompressImage] = useState([]);
  const [imageLink, setImageLink] = useState([]);

  const onChangeTextHandler = (eve) => {
    setMsgText(eve.target.value);
  };

  const sendMessage = () => {
    const message = {
      channelId: currChannel.id,
      sender: {
        userId: auth.userProfile.id,
        firstName: auth.userProfile.firstName,
        lastName: auth.userProfile.lastName,
      },
      type: currentImage && currentImage !== "" ? "image" : "text",
      readBy: [],
      message: msgText,
      image: imageLink ? imageLink : "",
      cimage: displayImage ? displayImage : "",
    };

    let empFound = currChannel.employee.find(
      (emp) => emp.id === auth.userProfile.id
    );

    if (!empFound) {
      const emp = {
        id: auth.userProfile.id,
        firstName: auth.userProfile.firstName,
        lastName: auth.userProfile.lastName,
        email: auth.userProfile.email,
        phone: auth.userProfile.phoneNumber,
      };
      if (currChannel.employee) {
        currChannel.employee.push(emp);
      } else {
        currChannel.employee = [emp];
      }
    }
    setMsgText("");
    currChannel.last_message = msgText;
    currChannel.readMsgUsers = [auth.userProfile.id];
    const channelUpdates = Object.assign({}, currChannel);
    updateChannel(currChannel.id, channelUpdates);
    sendMessageToChannel(
      message,
      (res) => {
        removeSelectedImage(0, false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const uploadPhoto = (currentFile, files, index, imgLink = []) => {
    const timestamp = moment().valueOf().toString();
    const storage = getStorage();
    const fileRef = ref(storage, timestamp);

    uploadBytes(fileRef, currentFile)
      .then(() => {
        return getDownloadURL(fileRef);
      })
      .then((downloadURL) => {
        imgLink.push(downloadURL);
        setImageLink(imgLink);

        if (files.length > index + 1) {
          uploadPhoto(files[index + 1], files, index + 1, imgLink);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resizeImage = (files, index, callbackAfterFinish, disImages = []) => {
    const prefixFiletype = files[index].type.toString();
    if (prefixFiletype.indexOf("image/") === 0) {
      try {
        Resizer.imageFileResizer(
          files[index],
          200,
          200,
          "JPEG",
          50,
          0,
          (uri) => {
            disImages.push(uri);

            if (files.length > index + 1) {
              setDisplayImage(disImages);
              resizeImage(files, index + 1, callbackAfterFinish, disImages);
            } else {
              callbackAfterFinish(disImages, 1);
            }
          },
          "base64",
          100,
          100
        );
      } catch (err) {
        callbackAfterFinish(err, 0);
        console.log(err);
      }
    } else {
      if (files.length > index + 1) {
        resizeImage(files, index + 1, callbackAfterFinish, disImages);
      } else {
        callbackAfterFinish(disImages, 1);
      }
    }
  };
  const onChoosePhoto = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCurrentImage(event.target.files[0]);
      const FilesArr = [];

      for (let fileKey in event.target.files) {
        const file = event.target.files[fileKey];

        if (file?.type) {
          FilesArr.push(file);
        }
      }
      if (FilesArr.length > 0) {
        for (let index = 0; index < FilesArr.length; index++) {
          resizeImage(
            FilesArr,
            index,
            (disImages, isSuccess) => {
              setDisplayImage(disImages);
              uploadPhoto(FilesArr[index], FilesArr, index, [...imageLink]);
            },
            [...displayImage]
          );
        }
      }
    } else {
      // this.setState({isLoading: false})
    }
  };

  const removeSelectedImage = (index, isDelete = true) => {
    if (isDelete) {
      if (imageLink.length > 0 && imageLink[index]) {
        const fileRef = ref(getStorage(), imageLink[index]);
        const imgLink = [...imageLink];
        imgLink.splice(index, 1);
        setImageLink(imgLink);
        const disImage = [...displayImage];
        disImage.splice(index, 1);
        setDisplayImage(disImage);

        deleteObject(fileRef)
          .then(() => {
            console.log("File Deleted");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setImageLink([]);
      setDisplayImage([]);
    }
    imgRef.current.value = "";
  };

  const checkSendIsDisbaled = () => {
    const userData = employee?.find((item) => item?.userId === auth.userProfile.id);
    if(userData?.status === false) {
      return false;
    } else if (currentImage !== "" && imageLink.length > 0) {
      return false;
    } else if (currentImage === "" && imageLink.length === 0) {
      return msgText.trim() === "";
    } else if (msgText.trim().length > 0) {
      return false;
    }
    return true;
  };
  return (
    <div className="msgBoxsender" key="msgBoxsender">
      {displayImage.length > 0 ? (
        <div className="selectedImage" key="selectedImage">
          {displayImage?.map((item, index) => {
            return (
              <div
                className="imgBox"
                key={`${new Date().toISOString()}${index}`}
              >
                <img src={`${item}`} height={50} width={50} alt={item} />
                <ClearIcon
                  className="imgBoxClearIcon"
                  onClick={() => {
                    removeSelectedImage(index);
                  }}
                />
                {!imageLink[index] && (
                  <div className="progressBar">
                    <CircularProgress size={20} />
                  </div>
                )}
              </div>
            );
          })}
          <div key={"addbutton"}>
            <Button
              variant="contained"
              className="flatbutton addmoreimg"
              onClick={() => {
                imgRef.current.click();
              }}
            >
              <PlusOneSharp color="#ffffff" />
            </Button>
          </div>
        </div>
      ) : null}

      <TextareaAutosize
        key="autoresizetext"
        minRows={5}
        className="msgTextbox"
        placeholder="Type here to send message..."
        value={msgText}
        onChange={onChangeTextHandler}
        maxLength={300}
      />
      <div className="buttonContainer" key="buttonContainer">
        <Button
          variant="contained"
          className="flatbutton sendbtn"
          onClick={sendMessage}
          disabled={checkSendIsDisbaled()}
        >
          <SendIcon color="#ffffff" />
        </Button>
        <input
          ref={imgRef}
          accept="image/*"
          className="viewInputGallery"
          type="file"
          multiple
          onChange={onChoosePhoto}
        />

        <Button
          variant="contained"
          className="flatbutton imgbtn"
          onClick={() => {
            imgRef.current.click();
          }}
        >
          <AddPhotoAlternateOutlinedIcon />
        </Button>
      </div>
    </div>
  );
};

const getNameCharcters = (user) => {
  return `${user?.firstName[0]}${user?.lastName[0]}`;
};
const MessageElement = ({ message, onMSGItemClick }) => {
  return (
    <div className="d-flex flex-direction-column w-100">
      {message?.imagesObj && message?.imagesObj?.length > 0 ? (
        <>
          {message?.imagesObj?.map((element, index) => {
            return (
              <div className="mt-10 w-100 rigthMessagess">
                <div className="nameBox">
                  {getNameCharcters(message.sender)}
                </div>
                <div className="msgTextBox">
                  <div className="dtDisplay">
                    {`${message.sender.firstName} ${
                      message.sender.lastName
                    }, ${moment(message.updatedOn).format("hh:mm a")}`}{" "}
                  </div>
                  {element.type === "image" &&
                    element.image !== "" &&
                    typeof element.cimage === "string" &&
                    element?.cimage.length > 0 && (
                      <div className="imageDisplay">
                        <div>
                          <ModalImage
                            small={element.cimage}
                            large={element.image}
                            alt={element.message}
                            showRotate
                          />
                        </div>
                      </div>
                    )}
                  {element.type === "image" &&
                    element.image !== "" &&
                    typeof element.cimage === "object" &&
                    element?.cimage.length > 0 &&
                    element?.cimage.map((item, index) => {
                      return (
                        <div className="imageDisplay">
                          <div>
                            <ModalImage
                              small={element.cimage}
                              large={element.image}
                              alt={element.message}
                              showRotate
                            />
                          </div>
                        </div>
                      );
                    })}
                  <div className="textDisplay">{element.message}</div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="mt-10 w-100 rigthMessagess">
          <div className="nameBox">{getNameCharcters(message.sender)}</div>
          <div className="msgTextBox">
            <div className="dtDisplay">
              {`${message.sender.firstName} ${
                message.sender.lastName
              }, ${moment(message.updatedOn).format("hh:mm a")}`}{" "}
            </div>

            <div className="textDisplay">{message.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};
const DisplayMessagesBox = ({ currChannel, isMobile, isLandscape }) => {
  const [messages, setMessages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(-10);

  const bottomRef = useRef(null);

  const auth = useContext(AuthContext);

  useEffect(() => {
    const unsubscribe = getMessages(
      currChannel.id,
      (res) => {
        if (res) {
          setMessages(res.slice(messageCount));
          setAllMessages(res);
          setTimeout(() => {
            setIsLoaded(true);
          }, 1000);
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
    return () => {
      if (currChannel.readMsgUsers) {
        const channelUpdates = { ...currChannel };
        if (!currChannel.readMsgUsers.includes(auth.userProfile.id)) {
          channelUpdates.readMsgUsers.push(auth.userProfile.id);
        }
        updateChannel(currChannel.id, channelUpdates);
      }
      unsubscribe();
    };
  }, [currChannel]);

  useEffect(() => {
    if (bottomRef) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [allMessages]);

  const checkPositionOfScroll = (e) => {
    if (
      isLoaded &&
      e.target.scrollTop <= 50 &&
      messageCount * -1 < allMessages.length
    ) {
      let mesCnt = messageCount;
      mesCnt += -1 * 10;
      setMessageCount(mesCnt);
      setMessages(allMessages.slice(mesCnt));
    }
  };
  const messageItemClick = (msgItem) => {
    // setLastImageURL(msgItem.image);
    // setIsLoadIMGPopup(true);
    // console.log(msgItem);
  };
  let dt = "";
  return (
    <>
      <div
        className="msgBox"
        style={
          !isMobile && !isLandscape
            ? { height: window.innerHeight - 350 }
            : { height: "50vh" }
        }
        onScroll={checkPositionOfScroll}
      >
        {messages.map((message) => {
          let msgDate = moment(message.updatedOn);
          let formattedDate;

          if (msgDate.isSame(moment(), "day")) {
            formattedDate = (
              <>
                <span>Today</span>
                <br />
                <span style={{ color: "#1e1e1e", fontWeight: "500" }}>
                  {msgDate.format("DD MMMM, YYYY")}
                </span>
              </>
            );
          } else if (msgDate.isSame(moment().subtract(1, "days"), "day")) {
            formattedDate = (
              <>
                <span>Yesterday</span>
                <br />
                <span style={{ color: "#1e1e1e", fontWeight: "500" }}>
                  {msgDate.format("DD MMMM, YYYY")}
                </span>
              </>
            );
          } else {
            formattedDate = (
              <>
                <span>{msgDate.format("dddd")}</span>
                <br />
                <span style={{ color: "#1e1e1e", fontWeight: "500" }}>
                  {msgDate.format("DD MMMM, YYYY")}
                </span>
              </>
            );
          }

          let returnArr = [];

          if (dt !== msgDate.format("DD MMMM, YYYY")) {
            returnArr.push(
              <div
                className="messageContainer"
                key={msgDate.format("DD MMMM, YYYY")}
              >
                <div className="dtMsg">
                  <div className="dtDisplay">{formattedDate}</div>
                </div>
              </div>
            );
            dt = msgDate.format("DD MMMM, YYYY");
          }

          if (auth.userProfile.id === message.sender.userId) {
            returnArr.push(
              <div className="messageContainer" key={message.id}>
                <div
                  className="rightMsg"
                  style={message.type === "image" ? { width: "90%" } : {}}
                >
                  <MessageElement
                    message={message}
                    onMSGItemClick={messageItemClick}
                  />
                </div>
              </div>
            );
          } else {
            returnArr.push(
              <div className="messageContainer" key={message.id}>
                <div
                  className="leftMsg"
                  style={message.type === "image" ? { width: "90%" } : {}}
                >
                  <MessageElement
                    message={message}
                    onMSGItemClick={messageItemClick}
                  />
                </div>
              </div>
            );
          }
          return returnArr;
        })}
        <div ref={bottomRef} />
      </div>
    </>
  );
};

function LiveChat(props) {
  const auth = useContext(AuthContext);
  const currChannel = auth.currentChannel;
  const isMobile = useMediaQuery("(max-width:767px)");
  const isLandscape = useMediaQuery(
    "(min-width: 771px) and (max-width: 940px)"
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [activeTab, setActiveTab] = React.useState("private");
  const [employee, setEmployee] = React.useState([]);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      padding: 0,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #bbbbbb",
    },
  }));

  useEffect(() => {
    getAllEmployee((res) => {
      if (res && res?.data) {
        setEmployee(res?.data);
      } else {
        setEmployee([]);
      }
    })
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    auth.setCurrentChannel(null);
  };

  const handleToggleTooltip = () => {
    setTooltipOpen((prev) => !prev);
  };

  return (
    <Container
      style={{ padding: isMobile || isLandscape ? "5px 0px 0px 0px" : "" }}
    >
      <PageTitle
        pageTitle="Chat Boards"
        showBreadcrumb={[{ label: "Home", to: "/" }, { label: "Channels" }]}
        BreadCrumbCurrentPageTitle={
          currChannel?.groupName ? currChannel?.groupName : "Chat Boards"
        }
        showLastSeen={true}
        live={true}
      />
      {!isMobile && !isLandscape ? (
        <div
          className={`sectionBox chatbox`}
          style={{ height: window.innerHeight - 180 }}
        >
          <Grid container className="chatboxcontianer">
            <Grid
              item
              xs={4}
              className="leftPanel"
              style={{ height: window.innerHeight - 180 }}
            >
              <ChatLeftPannel />
            </Grid>
            <Grid item xs={8} className="rightPanel">
              {currChannel ? (
                <>
                  <div className="msgBoxHeader">
                    <div className="title">{currChannel.groupName}</div>
                    <div>{currChannel.description}</div>
                    <HtmlTooltip
                      arrow
                      placement="left"
                      title={
                        <React.Fragment>
                          <div className="tooltip-heading">
                            Members ({currChannel?.employee?.length})
                          </div>
                          <div className="tooltip-container">
                            {currChannel?.employee.map((emp) => (
                              <div className="pt-5" key={emp.id}>
                                <b>{`${emp.firstName} ${emp.lastName}`}</b>
                              </div>
                            ))}
                          </div>
                        </React.Fragment>
                      }
                    >
                      <div className="peopleIcon">
                        <PermContactCalendarOutlinedIcon fontSize="large" />
                      </div>
                    </HtmlTooltip>
                  </div>
                  <DisplayMessagesBox currChannel={currChannel} />
                  <SendTextMessageBox currChannel={currChannel} employee={employee}/>
                </>
              ) : (
                <div className="noChannSelected"> Please select Channel</div>
              )}
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={`sectionBox2 chatbox`}>
          {!currChannel && (
            <div className="tabs mv-12">
              <div
                className={`tabItem ${
                  activeTab === "private" ? "activeTab" : ""
                }`}
                style={{ marginRight: "55px" }}
                onClick={() => handleTabClick("private")}
              >
                PRIVATE CHANNELS
              </div>
              <div
                className={`tabItem ${
                  activeTab === "public" ? "activeTab" : ""
                }`}
                onClick={() => handleTabClick("public")}
              >
                PUBLIC CHANNELS
              </div>
            </div>
          )}

          {!currChannel && (
            <div className="w-100">
              <div className="leftPanel">
                <ChatLeftPannel
                  activeTab={activeTab}
                  isMobile={isMobile}
                  isLandscape={isLandscape}
                />
              </div>
            </div>
          )}

          <div className="ph-15">
            {currChannel ? (
              <div className="rightPanel">
                <>
                  <div
                    className="chatboxcontianer d-flex flex-direction-column"
                    style={{ width: "385px" }}
                  >
                    <div className="msgBoxHeader d-flex flex-space-between flex-center">
                      <div className="title">
                        {currChannel.groupName}
                        <br />
                        <Typography
                          variant="body"
                          style={{
                            fontSize: "15px",
                            color: "#4c4c4c",
                            fontWeight: "450",
                            lineHeight: "35px",
                          }}
                        >
                          {currChannel.description}
                        </Typography>
                      </div>
                      <div onClick={handleToggleTooltip}>
                        <HtmlTooltip
                          arrow
                          placement="bottom"
                          open={tooltipOpen}
                          className="mr-10 mt-neg"
                          title={
                            <React.Fragment>
                              <div className="tooltip-heading">
                                Members ({currChannel?.employee?.length})
                              </div>
                              <div className="tooltip-container">
                                {currChannel?.employee.map((emp) => (
                                  <div className="pt-5" key={emp.id}>
                                    <b>{`${emp.firstName} ${emp.lastName}`}</b>
                                  </div>
                                ))}
                              </div>
                            </React.Fragment>
                          }
                        >
                          <div className="peopleIcon">
                            <PermContactCalendarOutlinedIcon fontSize="large" />
                          </div>
                        </HtmlTooltip>
                      </div>
                    </div>
                    <DisplayMessagesBox
                      currChannel={currChannel}
                      isMobile={isMobile}
                      isLandscape={isLandscape}
                    />
                  </div>
                  <div>
                    <SendTextMessageBox currChannel={currChannel} employee={employee}/>
                  </div>
                </>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </Container>
  );
}

export default LiveChat;
