import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import styles from "./PageTitle.module.scss";
import AuthContext from "../../hooks/authContext";
const PageTitle = (props) => {
  const auth = useContext(AuthContext);
  const showBreadcrumb = () => {
    return (
      <ul className={`clear breadcrumbs ${styles.breadcrumbWrap} `}>
        {props.showBreadcrumb?.map((bitem, bindex) => {
          return (
            <li key={bindex}>
              {bitem.label === "Channels" && props.live === true ? (
                <button
                  onClick={() => auth.setCurrentChannel(null)}
                  style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                  className={styles.channelLink}
                >
                  {bitem.label}
                </button>
              ) : (
                <Link to={bitem.to}        
                onClick={() => {
                  if (bitem.label === "Home") {
                    auth.setIsExpanded(false);
                  }
                  bitem.onclick?.();
                }}
                >{bitem.label}</Link>
              )}
              <span>
                <ChevronRightIcon />
              </span>
            </li>
          );
        })}
        <li>
          <span className={styles.active}>
            {props?.BreadCrumbCurrentPageTitle
              ? props?.BreadCrumbCurrentPageTitle
              : props.pageTitle}
          </span>
        </li>
      </ul>
    );
  };

  // const showLastSeen = () => {
  //   let profile = auth?.userProfile;
  //   return (
  //     auth?.status === true &&
  //     profile && (
  //       <>
  //         Last Login:{" "}
  //         <strong>
  //           {Moment(profile?.lastLogin).format("MMMM D, YYYY h:mm:ss")}
  //         </strong>
  //       </>
  //     )
  //   );
  // };

  return (
    <div className={styles.pageTitleWrap}>
      <div>
        <h4 className={styles.pageTitle}>{props.pageTitle}</h4>
        {props.showBreadcrumb &&
          props.showBreadcrumb !== false &&
          showBreadcrumb()}
      </div>
      {/* <div className={styles.rightTitle}>
        {props.showLastSeen && props.showLastSeen !== false && showLastSeen()}
      </div> */}
    </div>
  );
};

export default PageTitle;
