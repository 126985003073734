import React, { useEffect, useState } from "react";
import { Button, Container } from "@material-ui/core";
import { CSVLink } from "react-csv";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import moment from "moment-timezone";
import ThemeTable from "../../../../components/Table/ThemeTable";
import TableToolbar from "../../../../components/Table/TableToolbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import { getAllEmployee } from "../../../../services/apiservices/employee";
import { getSuppliesByDate } from "../../../../services/apiservices/supplies";
import dayjs from "dayjs";
import Popover from "@mui/material/Popover";
import { getClientsList } from "../../../../services/apiservices/client";
import { FormatedDates } from "../../../../services/helpers";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../Report.module.scss";
import { useMediaQuery } from "@mui/material";
import * as Excel from 'exceljs';
import { downloadExcel, downloadPDF } from "../../DownloadReportUtils";

const ActionRender = ({ rcellItem, clientsListCSV, setDownloadCSVData, downloadPDF, downloadExcel, startDate, endDate, handleEmailIconClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <div className={styles.Action}>
                <Link to={"/emailreports"} rel="noopener" className={styles.Action}>
                    <EmailRoundedIcon onClick={() => handleEmailIconClick(rcellItem)} />
                </Link>
                <MoreHorizRoundedIcon onClick={handlePopoverOpen} />
            </div>
            <Popover
                id="popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    // vertical: 'left',
                    horizontal: "left",
                }}
                transformOrigin={{
                    // vertical: 'left',
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",

                        // padding: '50px', // Add some padding
                    },
                }}
            >
                <div className={styles.box}>
                    <div className={styles.box2}>Download</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            left: 20,
                        }}
                    >
                        <div>
                            <CSVLink
                                data={clientsListCSV}
                                filename={`SupplyReport_${rcellItem.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}.csv`}
                                className={styles.Link}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                    setDownloadCSVData(event, done, rcellItem);
                                }}
                            >
                                csv
                            </CSVLink>
                        </div>
                        <div>
                            <button
                                onClick={() => { downloadExcel(rcellItem); }}
                                className={styles.Link}
                                style={{ background: 'none', border: 'none', textDecoration: 'underline', cursor: 'pointer', fontSize: 'small' }}
                            >
                                Excel
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => { downloadPDF(rcellItem); }}
                                className={styles.Link}
                                style={{ background: 'none', border: 'none', textDecoration: 'underline', cursor: 'pointer', fontSize: 'small' }}
                            >
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};


const SupplyReport = () => {
    const csvHeaders = [
        "Client Name",
        "Date",
        "Supply Id",
        "Created By",
        "Order Status",
        "Order Items",
        "Item Status"
      ];
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const [clientName, setClientName] = useState("All");
    const [clientNameList, setClientNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const [clientsList, setClientList] = useState([]);
    const [clientsListMaster, setClientsListMaster] = useState([]);
    const [clientsListCSV, setClientListCSV] = useState([csvHeaders]);
    const [status, setStatus] = useState("All");
    const [statusList, setStatusList] = useState([{ label: "All", value: "All" }]);
    const [priority, setPriority] = useState("All");
    const [priorityList, setPriorityList] = useState([{ label: "All", value: "All" }]);
    const [isLoading, setIsLoading] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    let navigate = useNavigate();


    const getColumns = () => {
        const columns = [
            {
                id: "clientName",
                numeric: false,
                disablePadding: false,
                label: "Client Name",
                isSortable: false,
            },
            {
                id: "dateOfRequest",
                numeric: false,
                disablePadding: false,
                label: "Date",
                isSortable: false,
            },
            {
                id: "supplyId",
                numeric: false,
                disablePadding: false,
                label: "Supply Id",
                isSortable: false,
            },
            {
                id: "createdBy",
                numeric: false,
                disablePadding: false,
                label: "Created By",
                isSortable: false,
            },
            {
                id: "status",
                numeric: false,
                disablePadding: false,
                label: "Order Status",
                isSortable: false,
            },
            {
                id: "itemsQty",
                numeric: false,
                disablePadding: false,
                label: "Order Items",
                isSortable: false,
            },
            {
                id: "itemStatus",
                numeric: false,
                disablePadding: false,
                label: "Item Status",
                isSortable: false,
            },
            {
                id: "Action",
                numeric: false,
                disablePadding: false,
                label: "Action",
                isSortable: false,
            },
        ];
        return columns;
    };

    const handleclientNameChange = (e) => {
        const str = e.target.value;
        console.log("str", str)
        setClientName(str);
        if (str === "All") {
            setClientList(clientsListMaster);
        } else {
            setClientList(() =>
                clientsListMaster.filter((itme) => {
                    let returnFlg = true;
                    if (str !== "All" && returnFlg) {
                        returnFlg = itme?.clientId === str;
                    }
                    return returnFlg;
                })
            );
        }
    };

    const handelStatusChange = (e) => {
        const str = e.target.value;
        setStatus(str);

        if (str === "All") {
            setClientList(clientsListMaster);
        } else {
            setClientList(() =>
                clientsListMaster.filter((itme) => {
                    let returnFlg = true;
                    if (clientName !== "All" && returnFlg) {
                        returnFlg = itme?.clientId === clientName;
                    }
                    if (str !== "All" && returnFlg) {
                        returnFlg = itme?.status === str;
                    }
                    return returnFlg;
                })
            );
        }
    };

    const handlePriorityChange = (e) => {
        const str = e.target.value;
        setPriority(str);

        if (str === "All") {
            setClientList(clientsListMaster);
        } else {
            setClientList(() =>
                clientsListMaster.filter((itme) => {
                    let returnFlg = true;
                    if (clientName !== "All" && returnFlg) {
                        returnFlg = itme?.clientId === clientName;
                    }
                    if (str !== "All" && returnFlg) {
                        returnFlg = itme?.status === status;
                    }
                    if (str !== "All" && returnFlg) {
                        returnFlg = itme?.priority === str;
                    }
                    return returnFlg;
                })
            );
        }
    };

const getDatatoDownload = (rcellItem) => {
        let clientCSV = [];
        if (!Array.isArray(rcellItem)) {
            clientCSV = clientsList?.filter(
                (item) => item?.clientNameId === rcellItem?.clientNameId
            );
            clientCSV = clientCSV?.flatMap((item) => {
                const supplyItemsRows = item?.supplyItems.map((itm) => {
                    return ['', '', '', '', '', `${itm.title}(${itm.qty})`, `${itm?.status}`]
                });
                return [
                    [
                        item?.clientName,
                        item?.dateOfRequest,
                        item?.supplyId,
                        item?.createdBy,
                        item?.status,
                        item?.itemsQty,
                        ''
                    ],
                    ...supplyItemsRows
                ];
            });
        } else {
            clientCSV = rcellItem?.flatMap((item) => {
                const supplyItemsRows = item?.supplyItems.map((itm) => {
                    return ['', '', '', '', '', `${itm.title}(${itm.qty})`, `${itm?.status}`]
                });
                return [
                    [
                        item?.clientName,
                        item?.dateOfRequest,
                        item?.supplyId,
                        item?.createdBy,
                        item?.itemsQty,
                        ''
                    ],
                    ...supplyItemsRows
                ];
            });
        }
        return clientCSV;
    }


    const getDatatoFullDownload = (rcellItem) => {
        if (rcellItem) {
            return [
                rcellItem?.clientName,
                rcellItem?.dateOfRequest,
                rcellItem?.supplyId,
                rcellItem?.createdBy,
                rcellItem?.status,
                rcellItem?.itemsQty,
                ''
            ];
        } else {
            return [
                rcellItem?.clientName,
                rcellItem?.dateOfRequest,
                rcellItem?.supplyId,
                rcellItem?.createdBy,
                rcellItem?.status,
                rcellItem?.itemsQty,
                ''
            ];
        }
    };

    const downloadForExcel = async (rcellItem, emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Supply Report');

        const clientCSV = getDatatoDownload(rcellItem);
        let fileName;
        if (Array.isArray(rcellItem)) {
            fileName = `SupplyReport_${rcellItem[0]?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        } else {
            fileName = `SupplyReport_${rcellItem?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        }
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
    }

    const downloadForPDF = async (rcellItem, emailSend) => {
        const clientCSV = getDatatoDownload(rcellItem);
        let fileName;
        if (Array.isArray(rcellItem)) {
            fileName = `SupplyReport_${rcellItem[0]?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        } else {
            fileName = `SupplyReport_${rcellItem?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        }
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
    }

    const setDownloadCSVData = (event, done, rcellItem) => {
        const clientCSV = getDatatoDownload(rcellItem);

        const reportName = "Supply Report";
        const clientName = Array.isArray(rcellItem) ? `${rcellItem[0]?.clientName}` : `${rcellItem?.clientName}`;
        const reportPeriod = `${moment(startDate).format("YYYY-MM-DD")} to ${moment(endDate).format("YYYY-MM-DD")}`;

        // Create the report details rows
        const reportDetails = [
            [`Client Name: ${clientName}`],
            [`Report Name: ${reportName}`],
            [`Report Period: ${reportPeriod}`],
            [],
            [...csvHeaders],
            ...clientCSV
        ];

        // Set the CSV data
        setClientListCSV(reportDetails);
        done();
    };

    const downloadForFullPDF = async (emailSend) => {
        const clientCSV = clientsList.flatMap((item) => {
            const mainRow = getDatatoFullDownload(item);
            const supplyItemsRows = item.supplyItems.map(itm => {
                return ['', '', '', '', '', `${itm.title}(${itm.qty})`, `${itm?.status}`]
            });
            return [mainRow, ...supplyItemsRows];
        });

        let clientNamefordwonLoad = 'All';
        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label;
        }

        let fileName = `SupplyReport_${clientNamefordwonLoad}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
    };


    const downloadForFullExcel = async (emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Supply Report');
        let clientNamefordwonLoad = 'All'

        const clientCSV = clientsList.flatMap((item) => {
            const mainRow = getDatatoFullDownload(item);
            const supplyItemsRows = item.supplyItems.map(itm => {
                return ['', '', '', '', '', `${itm.title}(${itm.qty})`, `${itm?.status}`]
            });
            return [mainRow, ...supplyItemsRows];
        });

        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
        }
        let fileName = `SupplyReport_${clientNamefordwonLoad}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
    }

    const handleEmailIconClick = async (rcellItem) => {
        const emailSend = true;
        const excelBlob = await downloadForExcel(rcellItem, emailSend);
        let name;
        if (Array.isArray(rcellItem)) {
            name = rcellItem[0]?.clientName;
        } else {
            name = rcellItem?.clientName;
        }
        localStorage.setItem("FileName", "SupplyReport");

        localStorage.setItem("ReportsName", "Client Report");

        localStorage.setItem("Name", name);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);

        const pdfBlob = await downloadForPDF(rcellItem, emailSend);
        localStorage.setItem("pdfData", pdfBlob);
        setIsLoading(true);

        // Convert the Excel blob to a base64-encoded string
        const reader = new FileReader();
        reader.readAsDataURL(excelBlob);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("emailData", base64Data);

            // Navigate to the email reports page
            navigate("/emailreports");
        };
    };


    const handelEmailFullReport = async () => {
        const emailSend = true;
        localStorage.setItem("FileName", "SupplyReport");

        localStorage.setItem("ReportsName", "Client Report");

        let clientNamefordwonLoad = 'All';
        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item?.value === clientName)[0].label;
        }

        localStorage.setItem("Name", clientNamefordwonLoad);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);
        setIsLoading(true);

        const fullReport = await downloadForFullPDF(emailSend);
        localStorage.setItem("fullReport", fullReport);

        const fullExcelReport = await downloadForFullExcel(emailSend);
        const reader = new FileReader();
        reader.readAsDataURL(fullExcelReport);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("fullExcelReport", base64Data);

            navigate("/emailreports");
        }
    }

    useEffect(() => {
        getAllEmployee((res) => {
            if (res) {
                setAllUsers(res);
            } else {
                setAllUsers();
            }
        })
    }, []);

    useEffect(() => {
        let paramStartDate = "";
        let paramEndDate = "";

        paramStartDate = startDate;
        paramEndDate = endDate;

        getReportListData(paramStartDate, paramEndDate);
    }, [startDate, endDate]);

    const getUniqueUserIds = (supplyData) => {
        const userIds = new Set();

        supplyData.forEach(item => {
            userIds.add(item.clientId);
        });

        return Array.from(userIds);
    };
    const getPriorityLabel = (priority) => {
        switch (priority) {
            case "1":
                return "High";
            case "2":
                return "Medium";
            case "3":
                return "Low";
            default:
                return "Unknown";
        }
    };

    const mapWithClientIds = async (clientIds, userData) => {
        getClientsList(clientIds, (result) => {
            const filteredClients = result.filter((item) =>
                userData.some((data) => data.clientId === item.id)
            );
            const clients = filteredClients.map((item) => ({
                value: item.id,
                label: item.clientName,
            }));
            setClientNameList([{ label: "All", value: "All" }, ...clients]);

            const uniqueStatuses = new Set();
            const uniquePriority = new Set();

            const finaleData = userData.map((resItem) => {
                const userDetails = result.filter(
                    (itm) => itm.id === resItem.clientId
                );

                let totalQuantity = 0;
                if (resItem.supplyItems) {
                    resItem.supplyItems.forEach((item) => {
                        totalQuantity += item.qty || 0;
                    });
                }

                const createdBy = allUsers?.data.filter((item => item.userId === resItem.createdBy));
                const date = FormatedDates(resItem.dateOfRequest);
                uniqueStatuses.add(resItem.status);
                const priority = getPriorityLabel(resItem.priority);
                uniquePriority.add(priority);

                const completedItems = resItem.supplyItems.filter((item) => item.status === "Delivered");

                const calculateCompletionPercentage = (completedData, totalData) => {
                    if (totalData === 0) {
                        return '0%'; // If there are no data, completion percentage is 0%
                    }
                    const percentage = Math.round((completedData / totalData) * 100); // Calculate completion percentage
                    return `${percentage}%`;
                };

                const completedData = completedItems.length;
                const totalData = resItem.supplyItems.length;
                const completionPercentage = calculateCompletionPercentage(completedData, totalData);

                resItem["clientName"] = userDetails[0]?.clientName;
                resItem["dateOfRequest"] = date;
                resItem["priority"] = priority;
                resItem["supplyId"] = `${String(resItem?.orderNumber).padStart(6, '0')}`;
                resItem["createdBy"] = `${createdBy[0].firstName} ${createdBy[0].lastName}`;
                resItem["itemsQty"] = `${totalQuantity}`;
                resItem["itemStatus"] = completionPercentage;

                return resItem;
            });

            const statusOptions = Array.from(uniqueStatuses).map((status) => ({
                label: status,
                value: status,
            }));

            setStatusList([{ label: "All", value: "All" }, ...statusOptions]);

            const priorityOptions = Array.from(uniquePriority).map((priority) => ({
                label: priority,
                value: priority,
            }));

            setPriorityList([{ label: "All", value: "All" }, ...priorityOptions]);


            let sortedData = sortingFunction(finaleData);
            setClientsListMaster(sortedData);

            setClientList(() =>
                sortedData?.filter((itme) => {
                    let returnFlg = true;
                    if (clientName !== "All") {
                        returnFlg = itme.clientId === clientName;
                    }
                    if (status !== "All" && returnFlg) {
                        returnFlg = itme.status === status;
                    }
                    if (priority !== "All" && returnFlg) {
                        returnFlg = itme.priority === priority;
                    }
                    return returnFlg;
                })
            );
        });
    };

    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.clientName < b.clientName) return -1;
            if (a.clientName > b.clientName) return 1;
            // If clientName is the same, sort by date
            if (a.dateOfRequest < b.dateOfRequest) return -1;
            if (a.dateOfRequest > b.dateOfRequest) return 1;
            // If clientName && Date is the same, sort by orderNumber
            if (a.orderNumber < b.orderNumber) return -1;
            if (a.orderNumber > b.orderNumber) return 1;
            return 0;
        });

        return result;
    };

    const getReportListData = async (startDt, endDt) => {
        const param = {
            startDate: `${dayjs(startDt).format("YYYY-MM-DD")}T00:00:00`,
            endDate: `${dayjs(endDt).format("YYYY-MM-DD")}T23:59:59`
        };
        try {
            const data = await getSuppliesByDate(param);
            const id = getUniqueUserIds(data);

            mapWithClientIds(id, data);

        } catch (error) {
            console.error("Error fetching report list data:", error);
        }
    }

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "clientName":
                if (
                    rowindex !== 0 &&
                    clientsList[rowindex - 1].clientName !== rcellItem.clientName
                ) {
                    return <div className={styles.themeCellContentt}>{rcellItem[hcellItem?.id]} </div>;
                } else if (rowindex === 0) {
                    return <div className={styles.themeCellContentt}>{rcellItem[hcellItem?.id]} </div>;
                } else {
                    return "";
                }
            case "itemsQty":
                return (
                    <div className={styles.TaskAccordion}>
                        <div className={styles.TaskSummary}>
                            {rcellItem[hcellItem?.id]}
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setExpandedRow(expandedRow === rowindex ? null : rowindex);
                                }}
                            >
                                {expandedRow === rowindex ? <ArrowDropUpIcon className={styles.AccordionIcon} /> : <ArrowDropDownIcon className={styles.AccordionIcon} />}
                            </div>
                        </div>
                        {expandedRow === rowindex && (
                            <div className={`${styles.TaskContent} ${styles.accordiann}`}>
                                {rcellItem.supplyItems.map((data, index) => (
                                    <div key={index} className={`${styles.accordiann}`}>
                                        <div>{data.title} ({data.qty})</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            case "itemStatus":
                return (
                    <div className="mt-20">
                            <div className={styles.TaskSummary}>
                                {/* {rcellItem[hcellItem?.id]} */}
                            </div>
                            {expandedRow === rowindex && (
                            <div className={`${styles.TaskContent} ${styles.accordiann}`}>
                                {rcellItem.supplyItems.map((data, index) => (
                                    <div key={index} className={`${styles.accordiann}`}>
                                        <div>{data?.status ? data.status : "Ordered"}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                        </div>
                )
            case "Action":
                if (isMobile || isLandscape) {
                    if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else {
                        return null;
                    }
                } else {
                    if (
                        rowindex !== 0 &&
                        clientsList[rowindex - 1].clientName !== rcellItem.clientName
                    ) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else {
                        return "";
                    }
                }

            default:
                return <div className={styles.themeCellContentt}>{rcellItem[hcellItem?.id]} </div>;
        }
    };

    const tableRightComponent = () => {
        return (
            <div className="d-flex w320 editEmp issueAlign ph-8">
                <div className="mr-24 d-flex flex-center"> <FilterAltIcon className="mv-7 issueicon" /> Filters </div>
                <div className="reportsMargin mv-7">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Start Date"
                            className="customDate customDatePicker"
                            value={startDate}
                            onChange={(newValue) => {
                                setStartDate(newValue);
                                if (newValue) {
                                    setEndDate(moment());
                                } else {
                                    setEndDate(null);
                                }
                            }}
                            maxDate={moment()}
                            views={['year', 'month', 'day']}
                            inputFormat="YYYY-MM-DD"
                        />
                    </LocalizationProvider>
                </div>
                <div className="reportsMargin mv-7">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="End Date"
                            className="customDate  customDatePicker"
                            value={endDate}
                            onChange={(newValue) => {
                                setEndDate(newValue);
                                if (!newValue) {
                                    setStartDate(null);
                                }
                            }}
                            maxDate={moment()}
                            views={['year', 'month', 'day']}
                            inputFormat="YYYY-MM-DD"
                        />
                    </LocalizationProvider>
                </div>
                <div className="reportsMargin mv-7">
                    <SingleSelect
                        value={clientName}
                        className={`field reportsSingleSelect`}
                        name={"clientName"}
                        label={"Client Name"}
                        onChange={(e) => handleclientNameChange(e)}
                        options={clientNameList}
                    />
                </div>
                <div className="reportsMargin mv-7">
                    <SingleSelect
                        value={status}
                        className={`field reportsSingleSelect`}
                        name={"orderStatus"}
                        label={"Order Status"}
                        onChange={(e) => handelStatusChange(e)}
                        options={statusList}
                    />
                </div>
                <div className="reportsMargin mv-7">
                    <SingleSelect
                        value={priority}
                        className={`field reportsSingleSelect`}
                        name={"priority"}
                        label={"Priority"}
                        onChange={(e) => handlePriorityChange(e)}
                        options={priorityList}
                    />
                </div>
            </div>
        );
    };

    return (
        <Container>
            <PageTitle
                pageTitle="Supply Reports"
                showBreadcrumb={[
                    { label: "Home", to: "/" },
                    { label: "Reports" },
                    { label: "Client Reports", to: "/reports/clientreport" }
                ]}
                BreadCrumbCurrentPageTitle={"Supply Reports"}
                showLastSeen={true}
            >
            </PageTitle>
            <div className={`sectionBox2`}>
                <TableToolbar
                    rightComponent={tableRightComponent()}
                />
                <ThemeTable
                    rows={clientsList}
                    headCells={getColumns()}
                    hidePagination={true}
                    isLoading={isLoading}
                    renderRowCell={renderRowCell}
                    supplyHeader={true}
                />
            </div>
            <div className={styles.report}>
            <div className={styles.download1}>
              <EmailRoundedIcon />
              <div>
                <Link to={clientsList.length !== 0 && "/emailreports"} rel="noopener" >
                  <Button
                    className={styles.actions}
                    disabled={clientsList.length === 0}
                    onClick={() => {
                      handelEmailFullReport()
                    }}
                  >
                    Full Report
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.download1}>
              <PictureAsPdfIcon />
              <div>
                <Button
                  className={styles.actions}
                  disabled={clientsList.length === 0}
                  onClick={() => {
                    downloadForFullPDF();
                  }}
                >
                  Download Full Report
                </Button>
              </div>
            </div>
          </div>
        </Container>
    )
}

export default SupplyReport;