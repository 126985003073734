import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import ConfirmCancel from "../../../components/DialogComponent/ConfirmCancel";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../components/AccordionComponent/AccordionComponent";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import ContactFieldsGroup from "./Contact/ContactFieldsGroup";
import confirm from "../../../components/DialogComponent/confirm";
import { EventEmitter } from "../../../services/event.service";
import validator from "validator";
import { getClientsList } from "../../../services/apiservices/client";
import { useEffect } from "react";
const stepId = 2;
function Step2(props) {
  const [stepContent, setContactValues] = useState({});
  const [stepError, setStepError] = useState({});
  const [numberAdditionalContact, setnoAdditionalContacts] = useState(1);
  const [clientsList, setClientsList] = useState([]);
  const {
    currentStep,
    goToStep,
    onCancelAll,

    loading,
    classes,
  } = props;

  const callgetClientListapi = async () => {
    await getClientsList(
      {},
      (res) => {
        if (res) {
          setClientsList(res);
        } else {
          setClientsList([]);
        }
      }
    );
  };
  useEffect(() => {
    callgetClientListapi();
  }, []);

  const setEditData = (editData) => {

    if (editData?.contact) {
      let primaryContact = editData?.contact?.filter((citem) => citem?.type === 'primary');
      let additionalContact = editData?.contact?.filter((citem) => citem?.type === 'additional');
      setContactValues({ primary: primaryContact, additional: additionalContact });
      setnoAdditionalContacts(additionalContact?.length);
    }
  }
  useEffect(() => {
    if (props?.editData !== false) {
      setEditData(props?.editData);
    }
  }, [props?.editData])

  const handleContactInputChange = (event, type, ind) => {
    const {
      target: { name, value },
    } = event;

    let prevVal = stepContent[type];
    if (prevVal?.length > 0) {
      prevVal[ind] = { ...prevVal[ind], [name]: value };
    } else {
      prevVal = [{ [name]: value }];
    }

    setContactValues({
      ...stepContent,
      [type]: prevVal,
    });
    let prevValError = stepError[type];
    if (prevValError?.length > 0) {
      prevValError[ind] = { ...prevValError[ind], [name]: "" };
    } else {
      prevValError = [{ [name]: "" }];
    }
    setStepError({ ...stepError, [type]: prevValError });
  };

  const validatePrimaryContact = () => {
    let valid = true;
    let primaryError = stepError.primary ? stepError.primary : [];
    const { primary } = stepContent;
    primary?.map((primaryItem, index) => {
      const { name, email, mobileNumber } = primaryItem;

      let emailchy = [];
      clientsList.forEach((itmr) => {
        if (Array.isArray(itmr.contact)) {
          itmr.contact.forEach((ittt) => {
            if (ittt.email === email) {
              emailchy.push(ittt.email);
            }
          });
        }
      });

      let phonechy = [];
      clientsList.forEach((itmr) => {
        if (Array.isArray(itmr.contact)) {
          itmr.contact.forEach((ittt) => {
            if (ittt.mobileNumber === mobileNumber) {
              phonechy.push(ittt.mobileNumber);
            }
          });
        }
      });

      let primaryErrorItem = primaryError ? primaryError[index] : {};
      if (typeof name === "undefined" || name === "") {
        valid = false;
        primaryErrorItem = { ...primaryErrorItem, name: "Name is Required" };
      } else {
        primaryErrorItem = { ...primaryErrorItem, name: "" };
      }
      if (typeof email === "undefined" || email === "") {
        valid = false;
        primaryErrorItem = { ...primaryErrorItem, email: "Email is Required" };
      } else if (!validator.isEmail(email.trim())) {
        valid = false;
        primaryErrorItem = { ...primaryErrorItem, email: "Email is Invalid " };
      } else if (emailchy === (email.trim())) {
        if (!props?.editData) {
          valid = false;
          primaryErrorItem = { ...primaryErrorItem, email: "Email is already registered " };
        }
      } else {
        primaryErrorItem = { ...primaryErrorItem, email: "" };
      }
      if (typeof mobileNumber !== "undefined" && mobileNumber !== "") {
        if (!validator.isMobilePhone(mobileNumber, ["en-US", "en-IN"])) {
          valid = false;
          primaryErrorItem = {
            ...primaryErrorItem,
            mobileNumber: "Invalid Mobile Number",
          };
        } else if (phonechy === mobileNumber) {
          if (!props?.editData) {
            valid = false;
            primaryErrorItem = {
              ...primaryErrorItem,
              mobileNumber: "Mobile Number already registered",
            };
          }
        } else {
          primaryErrorItem = { ...primaryErrorItem, mobileNumber: "" };
        }
      } else {
        primaryErrorItem = { ...primaryErrorItem, mobileNumber: "" };
      }
      primaryError[index] = primaryErrorItem;
      return primaryItem;
    });
    return { valid, primaryError: primaryError };
  };

  const validateAdditionalContact = () => {
    let valid = true;
    let additionalError = stepError.additional ? stepError.additional : [];
    const { additional, primary } = stepContent;
    additional?.map((additionalItem, index) => {
      const { name, email, mobileNumber } = additionalItem;

      let additionalErrorItem = additionalError ? additionalError[index] : {};
      if (
        (typeof name === "undefined" || name === "") &&
        (typeof email === "undefined" || email === "")
      ) {
      } else {
        if (typeof name === "undefined" || name === "") {
          valid = false;
          additionalErrorItem = {
            ...additionalErrorItem,
            name: "Name is Required",
          };
        } else if (name === primary[0].name) {
          valid = false;
          additionalErrorItem = {
            ...additionalErrorItem,
            name: "Use a Diffrent Name",
          };
        } else {
          additionalErrorItem = { ...additionalErrorItem, name: "" };
        }
        if (typeof email === "undefined" || email === "") {
          valid = false;
          additionalErrorItem = {
            ...additionalErrorItem,
            email: "Email is Required",
          };
        } else if (!validator.isEmail(email.trim())) {
          valid = false;
          additionalErrorItem = {
            ...additionalErrorItem,
            email: "Email is Invalid ",
          };
        } else if ((email.trim()) === ((primary[0].email).trim())) {
          valid = false;
          additionalErrorItem = {
            ...additionalErrorItem,
            email: "Use a Diffrent Email ",
          };

        }

        else {
          additionalErrorItem = { ...additionalErrorItem, email: "" };
        }
        if (typeof mobileNumber !== "undefined" && mobileNumber !== "") {
          if (!validator.isMobilePhone(mobileNumber, ["en-US", "en-IN"])) {
            valid = false;
            additionalErrorItem = {
              ...additionalErrorItem,
              mobileNumber: "Invalid Mobile Number",
            };
          } else if (mobileNumber === primary[0].mobileNumber) {
            valid = false;
            additionalErrorItem = {
              ...additionalErrorItem,
              mobileNumber: "Use a Diffrent Mobile Number ",
            };

          } else {
            additionalErrorItem = { ...additionalErrorItem, mobileNumber: "" };
          }
        } else {
          additionalErrorItem = { ...additionalErrorItem, mobileNumber: "" };
        }
      }
      additionalError[index] = additionalErrorItem;
      return additionalItem;
    });

    return { valid, additionalError: additionalError };
  };

  const validateAndGoNext = () => {
    let isValid = true;
    const { primary, additional } = stepContent;
    let contactErrorsMessages = stepError;
    if (primary?.length > 0) {
      contactErrorsMessages = {
        ...contactErrorsMessages,
        primaryContactError: "",
      };
      let checkPrimaryValid = validatePrimaryContact();
      if (checkPrimaryValid.valid !== true) {
        isValid = false;
        contactErrorsMessages = {
          ...contactErrorsMessages,
          primary: checkPrimaryValid?.primaryError,
        };
      } else {
        contactErrorsMessages = {
          ...contactErrorsMessages,
          primary: checkPrimaryValid?.primaryError,
        };
      }
    } else {
      isValid = false;
      contactErrorsMessages = {
        ...contactErrorsMessages,
        primaryContactError: "Primary Address is Required",
      };
    }
    if (additional?.length > 0) {
      let checkAdditionalValid = validateAdditionalContact();
      if (checkAdditionalValid.valid !== true) {
        isValid = false;
        contactErrorsMessages = {
          ...contactErrorsMessages,
          additional: checkAdditionalValid?.additionalError,
        };
      } else {
        contactErrorsMessages = {
          ...contactErrorsMessages,
          additional: checkAdditionalValid?.additionalError,
        };
      }
    }
    setStepError(contactErrorsMessages);
    if (isValid)
      props?.validateAndGoNext && props?.validateAndGoNext({ contact: stepContent });
  };

  const onAddAdditionalContact = async () => {
    if (numberAdditionalContact > 3) {
      await confirm("up to 4 additional contacts can be added.", {
        cancel: false,
      });
    } else {
      setnoAdditionalContacts(numberAdditionalContact + 1);
    }
  };

  const onEditLogo = () => {
    EventEmitter.dispatch("client_ChangeFormStep", 1);
  };

  const onDeleteAdditionalContact = async (id) => {
    await confirm(
      "Are you Sure you want to remove Additional contacts " + (id + 1)
    ).then(() => {
      let newContactValues = stepContent?.additional;
      newContactValues.splice(id, 1);
      setContactValues({
        ...stepContent,
        additional: newContactValues,
      });
      setnoAdditionalContacts(numberAdditionalContact - 1);
    });
  };

  const additionalContact = (additionalId) => {
    return (
      <div key={"additionalcontact" + additionalId} className="w-50 fleft pt-20 pr-20">
        <ContactFieldsGroup
          allowDelete={true}
          onDeleteContact={() => onDeleteAdditionalContact(additionalId)}
          contactTitle={"Additional Contacts " + (additionalId + 1)}
          values={
            stepContent?.additional ? stepContent?.additional[additionalId] : {}
          }
          errors={
            stepError?.additional ? stepError?.additional[additionalId] : {}
          }
          handleInputChange={(e) =>
            handleContactInputChange(e, "additional", additionalId)
          }
        />
      </div>
    );
  };

  return (
    <>
      <Accordion
        square
        disabled={stepId > currentStep}
        expanded={currentStep === stepId}
        onChange={() => goToStep(stepId)}
        className={`step${stepId}`}
      >
        <AccordionSummary
          aria-controls={`panel${stepId}d-content`}
          id={`panel${stepId}d-header`}
          className={
            currentStep === stepId || currentStep > stepId ? classes.complated : ""
          }
        >
          <Typography className={classes.heading}>
            Create Client Contact
          </Typography>
          <Typography className={classes.secondaryHeading}>Step {stepId}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={`stepContent w-100`}>
            {/* Content Starts */}

            <div className="stepSectionContent">
              <div className="clear">
                <div className="w-50 fleft pr-20">
                  <ContactFieldsGroup
                    contactTitle="Primary Contact"
                    values={stepContent?.primary ? stepContent?.primary[0] : {}}
                    errors={stepError?.primary ? stepError?.primary[0] : {}}
                    handleInputChange={(e) =>
                      handleContactInputChange(e, "primary", 0)
                    }
                  />
                  {typeof stepError?.primaryContactError !== "undefined" &&
                    stepError?.primaryContactError !== "" && (
                      <span className="small error color-danger">
                        {stepError?.primaryContactError}
                      </span>
                    )}
                </div>
                <div className="w-50 fleft pl-20">
                  {props?.stepContentValues?.clientLogo &&
                    props?.stepContentValues?.clientLogo !== "" && (
                      <>
                        <h5 className="stepSectionTitle">Client Logo</h5>
                        <div className="pl-12">
                          <img
                            src={props?.stepContentValues?.clientLogo}
                            alt=""
                          />
                          <Button
                            variant="link"
                            className="themebutton flatbutton linkbutton mt-5"
                            onClick={onEditLogo}
                          >
                            Edit
                          </Button>
                        </div>
                      </>
                    )}
                </div>
              </div>
              <div className="clear">
                {[...Array(numberAdditionalContact)].map((addItem, index) =>
                  additionalContact(index)
                )}
              </div>
              <Button
                variant="link"
                className="flatbutton themebutton linkbutton"
                onClick={onAddAdditionalContact}
              >
                Add more Contacts
              </Button>
            </div>

            {/* Content Ends */}
            <div className="buttonsWrap">
              <Button
                type="button"
                color={"primary"}
                variant={"contained"}
                className="flatbutton themeButton mr-25"
                onClick={() => validateAndGoNext()}
                disabled={loading === true ? true : false}
              >
                {loading === true ? "Loading..." : "Save & Proceed"}
              </Button>
              <DialogComponent
                buttonWrapClassName={"d-inline"}
                buttonClassName={"flatbutton"}
                buttonProps={{
                  size: "medium",
                  variant: "contained",
                  disabled: loading === true ? true : false,
                }}
                buttonLabel={"Cancel"}
                ModalTitle={"Are you sure you want to cancel this entry?"}
                ModalContent={<ConfirmCancel onCancelAll={onCancelAll} />}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
export default Step2;
