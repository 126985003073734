import React, { useEffect, useState } from "react";
import { Button, Container } from "@material-ui/core";
import { CSVLink } from "react-csv";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import moment from "moment-timezone";
import TableToolbar from "../../../../components/Table/TableToolbar";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ThemeTable from "../../../../components/Table/ThemeTable";
import { getIssuesByDate } from "../../../../services/apiservices/issues";
import dayjs from "dayjs";
import Popover from "@mui/material/Popover";
import { getClientsList } from "../../../../services/apiservices/client";
import { FormatedDates } from "../../../../services/helpers";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../Report.module.scss";
import { useMediaQuery } from "@mui/material";
import { downloadExcel, downloadPDF } from "../../DownloadReportUtils";
import * as Excel from 'exceljs';
import { getAllEmployee } from "../../../../services/apiservices/employee";

const ActionRender = ({ rcellItem, clientsListCSV, setDownloadCSVData, downloadPDF, downloadExcel, startDate, endDate, handleEmailIconClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <div className={styles.Action}>
        <Link to={"/emailreports"} rel="noopener" className={styles.Action}>
          <EmailRoundedIcon onClick={() => handleEmailIconClick(rcellItem)} />
        </Link>
        <MoreHorizRoundedIcon onClick={handlePopoverOpen} />
      </div>
      <Popover
        id="popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          // vertical: 'left',
          horizontal: "left",
        }}
        transformOrigin={{
          // vertical: 'left',
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "none",

            // padding: '50px', // Add some padding
          },
        }}
      >
        <div className={styles.box}>
          <div className={styles.box2}>Download</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              left: 20,
            }}
          >
            <div>
              <CSVLink
                data={clientsListCSV}
                filename={`IssuesReport_${rcellItem.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}.csv`}
                className={styles.Link}
                asyncOnClick={true}
                onClick={(event, done) => {
                  setDownloadCSVData(event, done, rcellItem);
                }}
              >
                csv
              </CSVLink>
            </div>
            <div>
              <button
                onClick={() => { downloadExcel(rcellItem); }}
                className={styles.Link}
                style={{ background: 'none', border: 'none', textDecoration: 'underline', cursor: 'pointer', fontSize:'small' }}
              >
                Excel
              </button>
            </div>
            <div>
              <button
                onClick={() => { downloadPDF(rcellItem); }}
                className={styles.Link}
                style={{ background: 'none', border: 'none', textDecoration: 'underline', cursor: 'pointer', fontSize:'small' }}
              >
                Pdf
              </button>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};


const IssuesReports = () => {

  const csvHeaders = [
    "Client Name",
    "Date",
    "Issue Number",
    "Created By",
    "Last Response",
    "Status",
    "Priority"
  ];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("All");
  const [clientName, setClientName] = useState("All");
  const [clientNameList, setClientNameList] = useState([
    { label: "All", value: "All" },
  ]);
  const [clientsList, setClientList] = useState([]);
  const [allUsers, setAllUsers] = useState();
  const [statusList, setStatusList] = useState([{ label: "All", value: "All" }]);
  const [clientsListMaster, setClientsListMaster] = useState([]);
  const [clientsListCSV, setClientListCSV] = useState([csvHeaders]);
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getColumns = () => {
    const columns = [
      {
        id: "clientName",
        numeric: false,
        disablePadding: false,
        label: "Client Name",
        isSortable: false,
      },
      {
        id: "date",
        numeric: false,
        disablePadding: false,
        label: "Date",
        isSortable: false,
      },
      {
        id: "issueNumber",
        numeric: false,
        disablePadding: false,
        label: "Issue Number",
        isSortable: false,
      },
      {
        id: "createdBy",
        numeric: false,
        disablePadding: false,
        label: "Created By",
        isSortable: false,
      },
      {
        id: "lastRes",
        numeric: false,
        disablePadding: false,
        label: "Last Response",
        isSortable: false,
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
        isSortable: false,
      },
      {
        id: "priority",
        numeric: false,
        disablePadding: false,
        label: "Priority",
        isSortable: false,
      },
      {
        id: "Action",
        numeric: false,
        disablePadding: false,
        label: "Action",
        isSortable: false,
      },
    ];
    return columns;
  };


  const handleclientNameChange = (e) => {
    const str = e.target.value;
    setClientName(str);
    if (str === "All") {
      setClientList(clientsListMaster);
    } else {
      setClientList(() =>
        clientsListMaster.filter((itme) => {
          let returnFlg = true;
          if (str !== "All" && returnFlg) {
            returnFlg = itme?.client.id === str;
          }
          return returnFlg;
        })
      );
    }
  };

  const handelStatusChange = (e) => {
    const str = e.target.value;
    setStatus(str);

    if (str === "All") {
      setClientList(clientsListMaster);
    } else {
      setClientList(() =>
        clientsListMaster.filter((itme) => {
          let returnFlg = true;
          if (clientName !== "All" && returnFlg) {
            returnFlg = itme?.client.id === clientName;
          }
          if (str !== "All" && returnFlg) {
            returnFlg = itme?.status === str;
          }
          return returnFlg;
        })
      );
    }
  };

  useEffect(() => {
    getAllEmployee((res)=>{
      if(res){
        setAllUsers(res);
      }else{
        setAllUsers();
      }
    })
  }, [])

  useEffect(() => {
    let paramStartDate = "";
    let paramEndDate = "";

    paramStartDate = startDate;
    paramEndDate = endDate;

    getReportListData(paramStartDate, paramEndDate);
  }, [startDate, endDate]);

  const getUniqueUserIds = (issueData) => {
    const userIds = new Set();

    issueData.forEach(item => {
      userIds.add(item.client.id);
    });

    return Array.from(userIds);
  };

  const getLatestResponse = (responses) => {
    if (!responses || Object.keys(responses).length === 0) return null;
    return Object.values(responses).reduce((latest, response) => {
      return latest.respondOn > response.respondOn ? latest : response;
    });
  };

  const mapWithClientIds = async (clientIds, userData) => {
    getClientsList(clientIds, (result) => {
      const filteredClients = result.filter((item) =>
        userData.some((data) => data.client.id === item.id)
      );
      const clients = filteredClients.map((item) => ({
        value: item.id,
        label: item.clientName,
      }));
      setClientNameList([{ label: "All", value: "All" }, ...clients]);

      const uniqueStatuses = new Set();

      const finaleData = userData.map((resItem) => {
        const userDetails = result.filter(
          (itm) => itm.id === resItem.client.id
        );

        const createdBy = allUsers?.data.filter((item => item.userId === resItem.createdBy));
        const date = FormatedDates(resItem.createdOn);
        const latestRes = getLatestResponse(resItem.responses);
        uniqueStatuses.add(resItem.status);

        resItem["clientName"] = userDetails[0]?.clientName;
        resItem["date"] = date;
        resItem["issueNumber"] = `${'IS - '}${String(resItem?.issueNumber).padStart(4, '0')}`;
        resItem["lastRes"] = FormatedDates(latestRes?.respondOn);
        resItem["createdBy"] = `${createdBy[0].firstName} ${createdBy[0].lastName}`;

        return resItem;
      });

      const statusOptions = Array.from(uniqueStatuses).map((status) => ({
        label: status,
        value: status,
      }));

      setStatusList([{ label: "All", value: "All" }, ...statusOptions]);

      let sortedData = sortingFunction(finaleData);
      setClientsListMaster(sortedData);

      setClientList(() =>
        sortedData?.filter((itme) => {
          let returnFlg = true;
          if (clientName !== "All") {
            returnFlg = itme.client.id === clientName;
          }
          if (status !== "All" && returnFlg) {
            returnFlg = itme.status === status;
          }
          return returnFlg;
        })
      );
    });
  };

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.clientName < b.clientName) return -1;
      if (a.clientName > b.clientName) return 1;
      // If clientName is the same, sort by date
      if (a.date < b.date) return -1;
      if (a.date > b.date) return 1;
      // If clientName && Dateis the same, sort by issueNumber
      if (a.issueNumber < b.issueNumber) return -1;
      if (a.issueNumber > b.issueNumber) return 1;
      return 0;
    });

    return result;
  };


  const getReportListData = async (startDt, endDt) => {
    const param = {
      startDate: `${dayjs(startDt).format("YYYY-MM-DD")}T00:00:00`,
      endDate: `${dayjs(endDt).format("YYYY-MM-DD")}T23:59:59`
    };
    try {
      const data = await getIssuesByDate(param);
      const id = getUniqueUserIds(data);

      mapWithClientIds(id, data);

    } catch (error) {
      console.error("Error fetching report list data:", error);
    }
  }

  const getDatatoDownload = (rcellItem) => {
    let clientCSV = [];
    if (!Array.isArray(rcellItem)) {
      clientCSV = clientsList?.filter(
        (item) => item.client.id === rcellItem.client.id
      );
      clientCSV = clientCSV?.map((item) => {
        return [
          item?.clientName,
          item?.date,
          item?.issueNumber,
          item?.createdBy,
          item.lastRes,
          item?.status,
          item?.priority,
        ];
      });
    } else {
      clientCSV = rcellItem?.map((item) => {
        return [
          item?.clientName,
          item?.date,
          item?.issueNumber,
          item?.createdBy,
          item.lastRes,
          item?.status,
          item?.priority,
        ];
      });
    }
    return clientCSV;
  };
  
  const getDatatoFullDownload = (rcellItem) => {
    if (rcellItem) {
      return [
        rcellItem?.clientName,
        rcellItem?.date,
        rcellItem?.issueNumber,
        rcellItem?.createdBy,
        rcellItem.lastRes,
        rcellItem?.status,
        rcellItem?.priority,
      ];
    } else {
      return [
        rcellItem?.clientName,
        rcellItem?.date,
        rcellItem?.issueNumber,
        rcellItem?.createdBy,
        rcellItem.lastRes,
        rcellItem?.status,
        rcellItem?.priority,
      ];
    }
  };
  
  const downloadForExcel = async (rcellItem, emailSend) => {
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet('Issues Report');

    const clientCSV = getDatatoDownload(rcellItem);
    let fileName;
    if (Array.isArray(rcellItem)) {
      fileName = `IssuesReport_${rcellItem[0]?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
    } else {
      fileName = `IssuesReport_${rcellItem?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
    }
    return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
  }

  const downloadForPDF = async (rcellItem, emailSend) => {
    const clientCSV = getDatatoDownload(rcellItem);
    let fileName;
    if (Array.isArray(rcellItem)) {
      fileName = `IssuesReport_${rcellItem[0]?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
    } else {
      fileName = `IssuesReport_${rcellItem?.clientName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
    }
    return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
  }

  const setDownloadCSVData = (event, done, rcellItem) => {
    const clientCSV = getDatatoDownload(rcellItem);

    const reportName = "Issues Report";
    const clientName = Array.isArray(rcellItem) ? `${rcellItem[0]?.clientName}` : `${rcellItem?.clientName}`;
    const reportPeriod = `${moment(startDate).format("YYYY-MM-DD")} to ${moment(endDate).format("YYYY-MM-DD")}`;
    // Create the report details rows
    const reportDetails = [
      [`Client Name: ${clientName}`],
      [`Report Name: ${reportName}`],
      [`Report Period: ${reportPeriod}`],
      [],
      [...csvHeaders],
      ...clientCSV
    ];

    // Set the CSV data
    setClientListCSV(reportDetails);
    done();
  };

   const downloadForFullPDF = async (emailSend) => {
      const clientCSV = clientsList.map((item) => getDatatoFullDownload(item));
      let clientNamefordwonLoad = 'All'
  
      if (clientName !== 'All') {
        clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
      }
      let fileName = `IssuesReport_${clientNamefordwonLoad}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`
      return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
    }
  
    const downloadForFullExcel = async (emailSend) => {
      let wb = new Excel.Workbook();
      let ws = wb.addWorksheet('Issues Report');
      let clientNamefordwonLoad = 'All'
  
      const clientCSV = clientsList.map((item) => getDatatoFullDownload(item));
  
      if (clientName !== 'All') {
        clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
      }
      let fileName = `IssuesReport_${clientNamefordwonLoad}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`
      return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
    }
  
    const handleEmailIconClick = async (rcellItem) => {
      const emailSend = true;
      const excelBlob = await downloadForExcel(rcellItem, emailSend);
      let name;
      if (Array.isArray(rcellItem)) {
        name = rcellItem[0]?.clientName;
      } else {
        name = rcellItem?.clientName;
      }
      localStorage.setItem("FileName", "IssuesReport");
  
      localStorage.setItem("ReportsName", "Client Report");
  
      localStorage.setItem("Name", name);
  
      localStorage.setItem("Date", startDate);
  
      localStorage.setItem("EndDate", endDate);
  
      const pdfBlob = await downloadForPDF(rcellItem, emailSend);
      localStorage.setItem("pdfData", pdfBlob);
      setIsLoading(true);
  
      // Convert the Excel blob to a base64-encoded string
      const reader = new FileReader();
      reader.readAsDataURL(excelBlob);
      reader.onloadend = function () {
        const base64Data = reader.result;
        localStorage.setItem("emailData", base64Data);
  
        // Navigate to the email reports page
        navigate("/emailreports");
      };
    };
  
    const handelEmailFullReport = async () => {
      const emailSend = true;
  
      localStorage.setItem("FileName", "IssuesReport");
  
      localStorage.setItem("ReportsName", "Client Report");
  
      let clientNamefordwonLoad = 'All'
  
      if (clientName !== 'All') {
        clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
      }
      localStorage.setItem("Name", clientNamefordwonLoad);
  
      localStorage.setItem("Date", startDate);
  
      localStorage.setItem("EndDate", endDate);
  
      setIsLoading(true);
      const fullReport = await downloadForFullPDF(emailSend);
      localStorage.setItem("fullReport", fullReport);
  
      const fullExcelReport = await downloadForFullExcel(emailSend);
      const reader = new FileReader();
      reader.readAsDataURL(fullExcelReport);
      reader.onloadend = function () {
        const base64Data = reader.result;
        localStorage.setItem("fullExcelReport", base64Data);
  
        navigate("/emailreports");
      }
    }
  

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "clientName":
        if (
          rowindex !== 0 &&
          clientsList[rowindex - 1].clientName !== rcellItem.clientName
        ) {
          return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
        } else if (rowindex === 0) {
          return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
        } else {
          return "";
        }
      case "Action":
        if (isMobile || isLandscape) {
          if (rowindex === 0) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else {
            return null;
          }
        } else {
          if (
            rowindex !== 0 &&
            clientsList[rowindex - 1].clientName !== rcellItem.clientName
          ) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else if (rowindex === 0) {
            return (
              <ActionRender
                rcellItem={rcellItem}
                clientsListCSV={clientsListCSV}
                setDownloadCSVData={setDownloadCSVData}
                downloadPDF={downloadForPDF}
                downloadExcel={downloadForExcel}
                startDate={startDate}
                endDate={endDate}
                handleEmailIconClick={handleEmailIconClick}
              />
            );
          } else {
            return "";
          }
        }
  
      default:
        return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
    }
  };
  

  const tableRightComponent = () => {
    return (
      <div className="d-flex w320 editEmp issueAlign ph-8">
        <div className="mr-24 d-flex flex-center"> <FilterAltIcon className="mv-7 issueicon" /> Filters </div>
        <div className="reportsMargin mv-7">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Start Date"
              className="customDate customDatePicker"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
                if (newValue) {
                  setEndDate(moment());
                } else {
                  setEndDate(null);
                }
              }}
              maxDate={moment()}
              views={['year', 'month', 'day']}
              inputFormat="YYYY-MM-DD"
            />
          </LocalizationProvider>
        </div>
        <div className="reportsMargin mv-7">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="End Date"
              className="customDate  customDatePicker"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
                if (!newValue) {
                  setStartDate(null);
                }
              }}
              maxDate={moment()}
              views={['year', 'month', 'day']}
              inputFormat="YYYY-MM-DD"
            />
          </LocalizationProvider>
        </div>
        <div className="reportsMargin mv-7">
          <SingleSelect
            value={clientName}
            className={`field reportsSingleSelect`}
            name={"clientName"}
            label={"Client Name"}
            onChange={(e) => handleclientNameChange(e)}
            options={clientNameList}
          />
        </div>
        <div className="reportsMargin mv-7">
          <SingleSelect
            value={status}
            className={`field reportsSingleSelect`}
            name={"status"}
            label={"Status"}
            onChange={(e) => handelStatusChange(e)}
            options={statusList}
          />
        </div>
      </div>
    );
  };

  return (
    <Container>
      <PageTitle
        pageTitle="Issues Reports"
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Reports" },
          { label: "Client Reports", to: "/reports/clientreport" }
        ]}
        BreadCrumbCurrentPageTitle={"Issue Reports"}
        showLastSeen={true}
      ></PageTitle>
      <div className={`sectionBox2`}>
        <TableToolbar
          rightComponent={tableRightComponent()}
        />
        <ThemeTable
          rows={clientsList}
          headCells={getColumns()}
          hidePagination={true}
          isLoading={isLoading}
          renderRowCell={renderRowCell}
        />
      </div>
      <div className={styles.report}>
            <div className={styles.download1}>
              <EmailRoundedIcon />
              <div>
                <Link to={clientsList.length !== 0 && "/emailreports"} rel="noopener" >
                  <Button
                    className={styles.actions}
                    disabled={clientsList.length === 0}
                    onClick={() => {
                      handelEmailFullReport()
                    }}
                  >
                    Full Report
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.download1}>
              <PictureAsPdfIcon />
              <div>
                <Button
                  className={styles.actions}
                  disabled={clientsList.length === 0}
                  onClick={() => {
                    downloadForFullPDF();
                  }}
                >
                  Download Full Report
                </Button>
              </div>
            </div>
          </div>
    </Container>
  )
}

export default IssuesReports;