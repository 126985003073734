import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
} from "@material-ui/core";

import { getRandomNumber } from "../../services/helpers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  selectinput: {
    padding: "8px 10px",
  },
  selectlabel: {
    transform: "translate(14px, 9px) scale(1)",
  },
  selectoption: {
    margin: 0,
  },
  selectinputDatetime: {
    padding: "12px 10px",
  },
  selectlabelDatetime: {
    transform: "translate(14px, 15px) scale(1)",
  },
}));

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  className: "custom-select-menu",
};

export default function SingleSelect(props) {
  const randomNumber = getRandomNumber();
  const classes = useStyles(props);
  const renderOption = (opItem, index) => {
    return (
      <MenuItem key={opItem?.label} value={opItem?.value}>
        <ListItemText className={classes.selectoption} primary={opItem?.label} />
      </MenuItem>
    );
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl} >
        {typeof props?.label !== "undefined" && props?.label !== "" && (
          <InputLabel className={
            props.datetime ? classes.selectlabelDatetime : classes.selectlabel
          } id={randomNumber + "demo-select-label"}>
            {props?.label}
          </InputLabel>
        )}
        <Select
          labelId={randomNumber + "demo-selectlabel"}
          id={randomNumber + "demo-select"}
          onChange={props?.onChange}
          label={
            props?.label !== "undefined" && props?.label !== ""
              ? props?.label
              : ""
          }
          value={props?.value ? props?.value : ''}
          MenuProps={MenuProps}
          disabled={props?.disabled ? props?.disabled : false}
          inputProps={{
            name: props?.name,
            className:`${props.datetime ? classes.selectinputDatetime : classes.selectinput} ${props?.className || ''}`,
          }}
        >
          {props.options?.length > 0 &&
            props.options?.map((opItem, index) => renderOption(opItem, index))}
        </Select>
      </FormControl>
    </>
  );
}
