import React, { useContext } from "react";
import { List } from "@material-ui/core";
import { sidebarMenuList } from "../../services/routes";
import MenuItem from "./MenuItem";
import AuthContext from "../../hooks/authContext";

const SidebarMenu = ({ props, handleDrawerToggle }) => {
  const auth = useContext(AuthContext);
  const isExpanded = auth.isExpanded;
  const setIsExpanded = auth.setIsExpanded;

  return (
    <List
      className={`sidebarMenuWrap ${isExpanded ? "collapsed" : "expanded"}`}
      >
      {sidebarMenuList.map((item, index) => (
        <MenuItem key={"menuitemwrap" + index} item={item} index={index} handleDrawerToggle={handleDrawerToggle}/>
      ))}
    </List>
  );
};

export default SidebarMenu;
