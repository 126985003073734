/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Dialog, DialogActions, DialogContent, Divider, Tooltip, Typography } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import PageTitle from "../../components/pageTitle/PageTitle";
import SingleSelect from "../../components/SelectField/SingleSelect";
import TableToolbar from "../../components/Table/TableToolbar";
import ThemeTable from "../../components/Table/ThemeTable";
import CommonContext from "../../hooks/commonContext";
import { getTimeZoneDate, groupByKey } from "../../services/helpers";
import VisibilityIcon from "@material-ui/icons/Visibility";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Columns, ColumnsRes } from "./IssuesHistoryTableColumns";
import { getIssuesList, unArchiveIssue } from "../../services/apiservices/issues";
import { useMediaQuery } from "@mui/material";
import IssueCommonCard from "../../components/ResponsiveCardComponent/IssuesComponent/IssuesListPageResponsive";
import { getAllEmployee } from "../../services/apiservices/employee";

const SuccessDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        This Issue has been successfully UnArchived. You can view the order in Issue Manage.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmationsDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>
        Unarchive
        <Divider className="mt-15" /></Typography>
      <DialogContent className="mb-10">
        Are you sure you want to "UnArchive" this issue?
      </DialogContent>
      <DialogActions className="mt-20 mb-15" >
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          No
        </Button>
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function IssueArchive(props) {
  const history = useNavigate();
  const commonContext = useContext(CommonContext);
  const [allIssues, setAllIssues] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [allClient, setAllClients] = useState([]);
  const [allPriority, setAllPriority] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filter, setFilterValues] = React.useState({
    priority: "any",
    client: "any",
  });
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const checkFilteredList = () => {
    if (allIssues) {
      let filteredSuppliesList = allIssues?.filter((row) => {
        let searchFilter = true,
          filterclient = true,
          filterpriority = true;
        
        if (filter?.client !== "" && filter?.client !== "any") {
          filterclient =
            String(row.clientName).toLowerCase() ===
            String(filter.client).toLowerCase();
        }
        if (filter?.priority !== "" && filter?.priority !== "any") {
          filterpriority =
            String(row.priority).toLowerCase() ===
            String(filter.priority).toLowerCase();
        }
        return searchFilter && filterclient && filterpriority;
      });
      setFilteredList(filteredSuppliesList);
    }
  };

  useEffect(() => {
    checkFilteredList();
  }, [allIssues, filter]);

  const manageDataList = (newRows) => {
    // set data for filter fields
    let allclients = groupByKey(newRows, "clientName");
    let allclientOptions = Object.keys(allclients)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllClient = allclientOptions ? allclientOptions : [];
    setAllClients([...finalSetAllClient]);

    let allpriority = groupByKey(newRows, "priority");
    let allpriorityOptions = Object.keys(allpriority)?.map((aacitem) => {
      return { label: aacitem, value: aacitem };
    });
    let finalSetAllpriority = allpriorityOptions ? allpriorityOptions : [];
    setAllPriority([...finalSetAllpriority]);
  };

  const fetchIssuesList = async () => {
    setIsLoading(true);
    try {
      const [employeeRes, issuesRes] = await Promise.all([
        new Promise((resolve) => getAllEmployee(resolve)),
        new Promise((resolve) => getIssuesList(resolve))
      ]);
  
      const Inactive = employeeRes?.data.filter(
        (item) => item.status === false || item.deleted
      );

      let newRows = issuesRes
        .map((ritem) => {
          const data = employeeRes?.data.find((item) => item.userId === ritem?.employee?.id);
          return {
          ...ritem,
          isuueId: `IS - ${String(ritem?.issueNumber).padStart(4, '0')}`,
          employeeName: ritem?.employee?.name,
          clientName: ritem?.client?.name,
          dateOfRequest: ritem?.dateOfRequest,
          inactive: Inactive,
          timeZone: data?.timeZone
        }});
        newRows = newRows.filter((ritem) =>
          ["Resolved", "Closed"].includes(ritem.status)
        );
  
      let sortedData = sortingFunction(newRows);
  
      await manageDataList(sortedData);
      await setAllIssues(sortedData);
    } catch (error) {
      console.log("Error fetching issues:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchIssuesList();
  }, []);

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.isuueId < b.isuueId) return -1;
      if (a.isuueId > b.isuueId) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };

  const setFilter = (name, value) => {
    setFilterValues({ ...filter, [name]: value });
  };

  const tableLeftComponent = () => {
    return <div className="d-flex flex-center"></div>;
  };
  const tableRightComponent = () => {
    return (
      <div className="d-flex flex-center w320 editEmp issueAlign pr-2">
        <div className="d-flex flex-center ml-12 w-100 mv-7">
          <SingleSelect
            label="Priority"
            value={filter?.priority ? filter?.priority : "any"}
            className={`field w90`}
            name={"priority"}
            onChange={(e) => {
              setFilter("priority", e?.target?.value);
            }}
            options={
              allPriority
                ? [{ label: "Any", value: "any" }, ...allPriority]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center ml-12 w-100 mv-7">
          <SingleSelect
            label="Client"
            value={filter?.client ? filter?.client : "any"}
            className={`field w90`}
            name={"client"}
            onChange={(e) => {
              setFilter("client", e?.target?.value);
            }}
            options={
              allClient
                ? [{ label: "Any", value: "any" }, ...allClient]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
      </div>
    );
  };
  const redirectToDetails = (id) => {
    if (typeof id !== "undefined" && id !== "")
      history("/issues/archive/" + id);
  };

  const handleCloseDialog = async () => {
    setDialogOpen(false);
    setDialogConfirmOpen(false);
    await fetchIssuesList();
  };
  const handleProceed = async (id) => {
    await unArchiveIssue(id);
    setDialogConfirmOpen(false);
    setDialogOpen(true);
    setSelectedItem(null);
  }

  const handelUnArchive = async (id) => {
    setDialogConfirmOpen(true);
    setSelectedItem(id);
  };

  const handleDialogConfirmClose = () => {
    setDialogConfirmOpen(false);
    setSelectedItem(null);
  };


  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "openedOn":
        return <>{getTimeZoneDate(rcellItem?.openedOn, rcellItem?.timeZone)} </>;
      case "closedOn":
        return <>{getTimeZoneDate(rcellItem.closedOn, rcellItem?.timeZone)} </>;
      case "employeeName":
        const isInactive = rcellItem?.inactive?.some((inactiveEmp) => inactiveEmp?.id === rcellItem?.employee.id);
        return (
          <p style={{ color: isInactive ? "#9fa6b3" : "" }}>{rcellItem?.employeeName}</p>
        );
      case "action":
        return (
          <>
            <div>
              <Tooltip title="UnArchive">
                <UnarchiveIcon className="color-primary" onClick={() => handelUnArchive(rcellItem?.id)} />
              </Tooltip>
              < VisibilityIcon className="ml-20 color-primary" onClick={() => redirectToDetails(rcellItem?.id)} />
            </div>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };


  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };


  return (
    <Container>
      <PageTitle
        pageTitle="Archive / History"
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Issue Management", to: "/issues/manage" },
        ]}
        showLastSeen={true}
      />

      <div className={`sectionBox2`}>
        <TableToolbar
          title=""
          leftComponent={tableLeftComponent()}
          rightComponent={tableRightComponent()}
        />
        {(!isMobile && !isLandscape) &&
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            isLoading={isLoading}
            hidePagination={false}
            renderRowCell={renderRowCell}
          />
        }
        {(isMobile || isLandscape) && (
          <>
            {filteredList.map((record, index) => {
              const isOpen = expanded === record?.id;
              return (
                <IssueCommonCard
                  index={index}
                  record={record}
                  isOpen={isOpen}
                  handleExpandClick={handleExpandClick}
                  redirectToDetails={redirectToDetails}
                  handelUnArchive={handelUnArchive}
                  columns={ColumnsRes}
                  issueUnarchive={true}
                />
              );
            })}
          </>
        )}
        <SuccessDialog open={dialogOpen} onClose={handleCloseDialog} />
        {dialogConfirmOpen && (
          <ConfirmationsDialog
            open={dialogConfirmOpen}
            onConfirm={() => handleProceed(selectedItem)}
            onClose={handleDialogConfirmClose}
          />
        )}
      </div>
    </Container>
  );
}

export default IssueArchive;
