import { collection, serverTimestamp, getDocs, doc, getDoc, setDoc, updateDoc, query, where } from 'firebase/firestore';
import { firebase } from '../firebase-config';
import { COLLECTIONS } from "../config";
import { getAuth } from 'firebase/auth';
import { formatSelectedDateTime, generateUniqueId } from '../helpers';
import moment from 'moment';

const formatDate = (dateStr) => {
    const formattedDate = moment(dateStr, 'MM/DD/YYYY').format('MMMM D, YYYY');
    if (!moment(formattedDate, 'MMMM D, YYYY').isValid()) {
        return null; 
    }
    return formattedDate;
};

export async function saveAppointment(appointmentData, recurringDates) {
    try {
        const auth = getAuth();
        // const currentUserId = auth.currentUser?.uid;
        const currentUserId = appointmentData?.userId;

        if (!currentUserId) {
            throw new Error("User is not authenticated.");
        }

        const clientDocRef = doc(firebase, COLLECTIONS.CLIENT, appointmentData?.clientId);
        const clientDocSnap = await getDoc(clientDocRef);
        const clientData = clientDocSnap.exists() ? clientDocSnap.data() : null;
        
        const appointmentsCollectionRef = collection(firebase, COLLECTIONS.APPOINMENTS);
        

        if (appointmentData.type === "Recurring Appointment") {
            if (typeof appointmentData.numberOfTimes === 'undefined') {
                throw new Error("numberOfTimes is required for recurring appointments.");
            }
            if (!recurringDates || recurringDates.length === 0) {
                throw new Error("recurringDates is required for recurring appointments.");
            }
            const appointments = [];

            for (const date of recurringDates) {
                const formattedDate = formatDate(date);
                const newId = generateUniqueId();
                const customId = `${newId}-${appointmentData.clientId}-${currentUserId}-${moment().format('YYYY-MM-DD')}`;
                const appointmentDateTime = formatSelectedDateTime(date, appointmentData.time, appointmentData.calendarTimezone);
        
                const docRef = doc(appointmentsCollectionRef, customId);
                await setDoc(docRef, {
                    ...appointmentData,
                    date: formattedDate,
                    appointmentDateTime: appointmentDateTime,
                    id: customId,
                    forms: [
                        {
                            values: [
                                {
                                    name: "Address",
                                    value: clientData?.addressInfo?.address ?? null
                                },
                                {
                                    name: "City",
                                    value: clientData?.addressInfo?.city ?? null
                                },
                                {
                                    name: "State",
                                    value: clientData?.addressInfo?.state ?? null
                                },
                                {
                                    name: "ZipCode",
                                    value: clientData?.addressInfo?.zip ?? null
                                },
                                {
                                    name: "Shift Name",
                                    value: appointmentData.shiftName  ? appointmentData.shiftName : "Morning"
                                }
                            ]
                        }
                    ],
                    userId: currentUserId,
                    createdAt: serverTimestamp(),
                });

                appointments.push({
                    ...appointmentData,
                    id: customId,
                });
            }

            return {
                success: true,
                data: appointments,
            };
        } else {
            if (typeof appointmentData.numberOfTimes === 'undefined') {
                appointmentData.numberOfTimes = 2;
            }
            const newId = generateUniqueId();
            const customId = `${newId}-${appointmentData.clientId}-${currentUserId}-${moment().format('YYYY-MM-DD')}`;

            const docRef = doc(appointmentsCollectionRef, customId);
            await setDoc(docRef, {
                ...appointmentData,
                userId: currentUserId,
                forms: [
                    {
                        values: [
                            {
                                name: "Address",
                                value: clientData?.addressInfo?.address ?? null
                            },
                            {
                                name: "City",
                                value: clientData?.addressInfo?.city ?? null
                            },
                            {
                                name: "State",
                                value: clientData?.addressInfo?.state ?? null
                            },
                            {
                                name: "ZipCode",
                                value: clientData?.addressInfo?.zip ?? null
                            },
                            {
                                name: "Shift Name",
                                value: appointmentData.shiftName  ? appointmentData.shiftName : "Morning"
                            }
                        ]
                    }
                ],
                id: customId,
                createdAt: serverTimestamp(),
            });

            return {
                success: true,
                data: [{
                    ...appointmentData,
                    id: customId,
                }],
            };
        }
    } catch (error) {
        console.error("Error saving appointments: ", error);
        return {
            success: false,
            error: error.message || "An error occurred while saving the appointments",
        };
    }
}

async function handleAppointmentUpdateOrCreate(
    docRef,
    timeSheetDocRef,
    appointmentData,
    formattedDate,
    appointmentDateTime,
    appointmentsCollectionRef,
    currentUserId
) {
    const docSnap = await getDoc(docRef);
    const timeSheetDocSnap = await getDoc(timeSheetDocRef);

    const clientDocRef = doc(firebase, COLLECTIONS.CLIENT, appointmentData?.clientId);
    const clientDocSnap = await getDoc(clientDocRef);
    const clientData = clientDocSnap.exists() ? clientDocSnap.data() : null;

    if (timeSheetDocSnap.exists()) {
        const timeSheetData = timeSheetDocSnap.data();
        const hasCheckInOrCheckOut = timeSheetData?.checkInDateTime || timeSheetData?.checkOutDateTime;

        if (hasCheckInOrCheckOut) {
            const newId = generateUniqueId();
            const dateCreated = moment().format('MMMM DD, YYYY');
            const datetimeCreated = moment().format('YYYY-MM-DDTHH:mm:ssZ');
            const newCustomId = `${newId}-${appointmentData.clientId}-${currentUserId}-${moment().format('YYYY-MM-DD')}`;
            await setDoc(doc(appointmentsCollectionRef, newCustomId), {
                ...appointmentData,
                date: formattedDate,
                appointmentDateTime,
                dateCreated,
                datetimeCreated,
                id: newCustomId,
                forms: [
                    {
                        values: [
                            {
                                name: "Address",
                                value: clientData?.addressInfo?.address ?? null
                            },
                            {
                                name: "City",
                                value: clientData?.addressInfo?.city ?? null
                            },
                            {
                                name: "State",
                                value: clientData?.addressInfo?.state ?? null
                            },
                            {
                                name: "ZipCode",
                                value: clientData?.addressInfo?.zip ?? null
                            },
                            {
                                name: "Shift Name",
                                value: appointmentData.shiftName  ? appointmentData.shiftName : "Morning"
                            }
                        ]
                    }
                ],
                userId: currentUserId,
                createdAt: serverTimestamp(),
            });
        } else {
            if (!docSnap.exists()) {
                throw new Error(`Appointment not found for date: ${formattedDate}`);
            }

            await updateDoc(docRef, {
                ...appointmentData,
                date: formattedDate,
                appointmentDateTime: appointmentDateTime,
                updatedAt: serverTimestamp(),
            });
        }
    }
}

async function updateRecurringAppointments(appointmentData, recurringDates, customIds, appointmentsCollectionRef, currentUserId, timeSheetCollectionRef) {
    const totalRecurringDates = recurringDates.length;
    const totalCustomIds = customIds.length;

    // Case 1: More recurring dates than customIds, update first N customIds and create a new one for the last date
    if (totalRecurringDates > totalCustomIds) {
        for (let i = 0; i < totalCustomIds; i++) {
            const currentDate = recurringDates[i];
            const formattedDate = formatDate(currentDate);
            const appointmentDateTime = formatSelectedDateTime(currentDate, appointmentData.time, appointmentData.calendarTimezone);
            const docRef = doc(appointmentsCollectionRef, customIds[i]);
            const timeSheetdocRef = doc(timeSheetCollectionRef, customIds[i]);
            await handleAppointmentUpdateOrCreate(
                docRef,
                timeSheetdocRef,
                appointmentData,
                formattedDate,
                appointmentDateTime,
                appointmentsCollectionRef,
                currentUserId
            );
        }

        // Create new appointments for the remaining dates
        for (let i = totalCustomIds; i < totalRecurringDates; i++) {
            const currentDate = recurringDates[i];
            const formattedDate = formatDate(currentDate);
            const appointmentDateTime = formatSelectedDateTime(currentDate, appointmentData.time, appointmentData.calendarTimezone);
            const newId = generateUniqueId();
            const newCustomId = `${newId}-${appointmentData.clientId}-${currentUserId}-${moment().format('YYYY-MM-DD')}`;
            const dateCreated = moment().format('MMMM DD, YYYY');
            const datetimeCreated = moment().format('YYYY-MM-DDTHH:mm:ssZ');

            const clientDocRef = doc(firebase, COLLECTIONS.CLIENT, appointmentData?.clientId);
            const clientDocSnap = await getDoc(clientDocRef);
            const clientData = clientDocSnap.exists() ? clientDocSnap.data() : null;
        
            await setDoc(doc(appointmentsCollectionRef, newCustomId), {
                ...appointmentData,
                date: formattedDate,
                appointmentDateTime: appointmentDateTime,
                dateCreated : dateCreated,
                datetimeCreated : datetimeCreated,
                id: newCustomId,
                forms: [
                    {
                        values: [
                            {
                                name: "Address",
                                value: clientData?.addressInfo?.address ?? null
                            },
                            {
                                name: "City",
                                value: clientData?.addressInfo?.city ?? null
                            },
                            {
                                name: "State",
                                value: clientData?.addressInfo?.state ?? null
                            },
                            {
                                name: "ZipCode",
                                value: clientData?.addressInfo?.zip ?? null
                            },
                            {
                                name: "Shift Name",
                                value: appointmentData.shiftName  ? appointmentData.shiftName : "Morning"
                            }
                        ]
                    }
                ],
                userId: currentUserId,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
            });
        }

        return {
            success: true,
            data: { ...appointmentData },
        };
    }

    // Case 2: More customIds than recurring dates, update first N customIds
    if (totalRecurringDates < totalCustomIds) {
        for (let i = 0; i < totalRecurringDates; i++) {
            const currentDate = recurringDates[i];
            const appointmentDateTime = formatSelectedDateTime(currentDate, appointmentData.time, appointmentData.calendarTimezone);
            const formattedDate = formatDate(currentDate);
            const docRef = doc(appointmentsCollectionRef, customIds[i]);
            const timeSheetdocRef = doc(timeSheetCollectionRef, customIds[i]);

            await handleAppointmentUpdateOrCreate(
                docRef,
                timeSheetdocRef,
                appointmentData,
                formattedDate,
                appointmentDateTime,
                appointmentsCollectionRef,
                currentUserId
            );
        }
        return {
            success: true,
            data: { ...appointmentData },
        };
    }

    // Case 3: Equal number of recurring dates and customIds, update all
    if (totalRecurringDates === totalCustomIds) {
        for (let i = 0; i < totalRecurringDates; i++) {
            const currentDate = recurringDates[i];
            const formattedDate = formatDate(currentDate);
            const appointmentDateTime = formatSelectedDateTime(currentDate, appointmentData.time, appointmentData.calendarTimezone);
            const docRef = doc(appointmentsCollectionRef, customIds[i]);
            const timeSheetdocRef = doc(timeSheetCollectionRef, customIds[i]);

            await handleAppointmentUpdateOrCreate(
                docRef,
                timeSheetdocRef,
                appointmentData,
                formattedDate,
                appointmentDateTime,
                appointmentsCollectionRef,
                currentUserId
            );
        }

        return {
            success: true,
            data: { ...appointmentData },
        };
    }
}

export async function updateAppointments(appointmentData, recurringDates, customIds) {
    try {
        const auth = getAuth();
        const currentUserId = appointmentData?.userId;

        if (!currentUserId) {
            throw new Error("User is not authenticated.");
        }

        const appointmentsCollectionRef = collection(firebase, COLLECTIONS.APPOINMENTS);
        const timeSheetCollectionRef = collection(firebase, COLLECTIONS.TIMESHEETS);

        if (appointmentData?.type === "Recurring Appointment" && recurringDates) {
            return await updateRecurringAppointments(appointmentData, recurringDates, customIds, appointmentsCollectionRef, currentUserId, timeSheetCollectionRef);
        } else {
            const docRef = doc(appointmentsCollectionRef, customIds);
            const timeSheetdocRef = doc(timeSheetCollectionRef, customIds);
            const docSnap = await getDoc(docRef);

            const timeSheetDocSnap = await getDoc(timeSheetdocRef);
            const clientDocRef = doc(firebase, COLLECTIONS.CLIENT, appointmentData?.clientId);
            const clientDocSnap = await getDoc(clientDocRef);
            const clientData = clientDocSnap.exists() ? clientDocSnap.data() : null;

            if (timeSheetDocSnap.exists()) {
                const timeSheetData = timeSheetDocSnap.data();
                const hasCheckInOrCheckOut = timeSheetData?.checkInDateTime || timeSheetData?.checkOutDateTime;

                if (hasCheckInOrCheckOut) {
                    const newId = generateUniqueId();
                    const newCustomId = `${newId}-${appointmentData.clientId}-${currentUserId}-${moment().format('YYYY-MM-DD')}`;
                    const dateCreated = moment().format('MMMM DD, YYYY');
                    const datetimeCreated = moment().format('YYYY-MM-DDTHH:mm:ssZ');

                    await setDoc(doc(appointmentsCollectionRef, newCustomId), {
                        ...appointmentData,
                        dateCreated,
                        datetimeCreated,
                        id: newCustomId,
                        forms: [
                            {
                                values: [
                                    {
                                        name: "Address",
                                        value: clientData?.addressInfo?.address ?? null
                                    },
                                    {
                                        name: "City",
                                        value: clientData?.addressInfo?.city ?? null
                                    },
                                    {
                                        name: "State",
                                        value: clientData?.addressInfo?.state ?? null
                                    },
                                    {
                                        name: "ZipCode",
                                        value: clientData?.addressInfo?.zip ?? null
                                    },
                                    {
                                        name: "Shift Name",
                                        value: appointmentData.shiftName  ? appointmentData.shiftName : "Morning"
                                    }
                                ]
                            }
                        ],
                        userId: currentUserId,
                        createdAt: serverTimestamp(),
                    });
                } else {
                    if (!docSnap.exists()) {
                        throw new Error(`Appointment not found for date`);
                    }

                    await updateDoc(docRef, {
                        ...appointmentData,
                        updatedAt: serverTimestamp(),
                    });
                }
            }
            return {
                success: true,
                data: {
                    ...appointmentData,
                },
            };

        }
    } catch (error) {
        console.error("Error updating appointment: ", error);
        return {
            success: false,
            error: error.message || "An error occurred while updating the appointment.",
        };
    }
}


export async function getAllAppoinment() {
    try {
        const appointmentsCollectionRef = collection(firebase, COLLECTIONS.TIMESHEETS);
        const snapshot = await getDocs(appointmentsCollectionRef);

        const appointments = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        return {
            success: true,
            appointments,
        };

    } catch (error) {
        console.error("Error getting docs", error); return {
            success: false,
            error: error.message || "An error occurred while getting the appointment",
        };
    }
}
export async function getFilterAppoinments(minDate, maxDate) {
    try {

        maxDate  = `${maxDate}T23:59:59`;
        
        const appointmentsCollectionRef = query(collection(firebase, COLLECTIONS.TIMESHEETS), where("appointmentDate","==", moment(minDate).format("MMMM D, YYYY") ));
        // const appointmentsCollectionRef = collection(firebase, COLLECTIONS.APPOINMENTS);
        const snapshot = await getDocs(appointmentsCollectionRef);

        const appointments = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        return {
            success: true,
            appointments,
        };

    } catch (error) {
        console.error("Error getting docs", error); return {
            success: false,
            error: error.message || "An error occurred while getting the appointment",
        };
    }
}

export async function getOneAppointment(id) {
    try {
        const appointmentDocRef = doc(firebase, COLLECTIONS.APPOINMENTS, id);
        const appointmentDoc = await getDoc(appointmentDocRef);

        if (!appointmentDoc.exists()) {
            return {
                success: false,
                error: "Appointment not found",
            };
        }

        return {
            success: true,
            appointment: {
                id: appointmentDoc.id,
                ...appointmentDoc.data(),
            },
        };

    } catch (error) {
        console.error("Error getting appointment", error);
        return {
            success: false,
            error: error.message || "An error occurred while getting the appointment",
        };
    }
}


export async function DeleteAppointment(ids) {
    if (!ids) {
        return {
            success: false,
            error: "No appointment ID(s) provided",
        };
    }

    try {
        if (Array.isArray(ids)) {
            const updatePromises = ids.map(id => {
                const appointmentDocRef = doc(firebase, COLLECTIONS.APPOINMENTS, id);
                return updateDoc(appointmentDocRef, { canceled: true });
            });
            await Promise.all(updatePromises);
            return {
                success: true,
                message: "Appointments canceled successfully",
            };
        } else {
            const appointmentDocRef = doc(firebase, COLLECTIONS.APPOINMENTS, ids);
            await updateDoc(appointmentDocRef, { canceled: true });
            return {
                success: true,
                message: "Appointment canceled successfully",
            };
        }
    } catch (error) {
        console.error("Error updating document(s):", error);
        return {
            success: false,
            error: error.message || "An error occurred while canceling the appointment(s)",
        };
    }
}